/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React from "react";

import { Link, useHistory } from "react-router-dom";
import wishlist from "../../assets/images/wishlist.png";
import PageLoading from "../../components/PageLoading";
import { toast, MDBModal, MDBModalBody } from "mdbreact";
import Modal from "../../components/ModalV2";
import {
  getDefValues,
  getCurrency,
  getImagePath,
  getSyncStoresInfo,
  getRenderObj,
  getLanguage,
  showMessage,
  showError,
  callApi,
  toFixed,
} from "../../common/functions";
const foldername = process.env.REACT_APP_FOLDER_LAYOUT;
const windowHeight = window.innerHeight;
const LoadList = (props) => {
  const history = useHistory();
  const [showDForm, setShowDForm] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [dedications, setDedications] = React.useState([]);

  const toggleShowComment = (event) => {
    setShowDForm(!showDForm);
  };

  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  //const [storeStatus, setStoreStatus] = React.useState(false);

  //const [skus, setSkus] = React.useState([]);
  const [sku, setSku] = React.useState("");
  //const [skuToAdd, setSkuToAdd] = React.useState("");
  //const [storeid, setStoreid] = React.useState(0);

  //const [dedicationName, setDedicationName] = React.useState("");
  //const [dedicationMessage, setDedicationMessage] = React.useState("");

  //const [externalOrderFormData, setExternalOrderFormData] = React.useState({});
  //const [externalOrderFormConfig, setExternalOrderFormConfig] = React.useState({} );

  //const [total, setTotal] = React.useState(0.0);
  const [quantity, setQuantity] = React.useState(0);
  const [quantityReq, setQuantityReq] = React.useState(0);

  const [isloading, setIsloading] = React.useState(false);
  //const [stores, setStores] = React.useState([]);

  React.useEffect(() => {
    //var st = getSyncStoresInfo();
    //setStores(st.stores);
    //setStoreid(st.stores[0].storeId);
    var products = [];
    var dedications = [];
    var _dedications = [];
    props.products.map(function (prod) {
      if (prod.orders.length > 0) {
        prod.orders.map(function (order) {
          if (order.buyer) {
            if (_dedications.indexOf(order.buyer) < 0) {
              _dedications.push(order.buyer);
              let data = order.buyer.split(":");
              if (data.length === 1) {
                dedications.push({
                  name: "",
                  message: data[0],
                });
              } else if (data.length === 2) {
                dedications.push({
                  name: data[0] + ":",
                  message: data[1],
                });
              } else {
                let name = data[0];
                data.splice(0, 1);
                let message = data.toString();
                dedications.push({
                  name: name + ":",
                  message: message,
                });
              }
            }
          }
          products.push({
            sku: prod.productsku,
            name: prod.productname,
            image: prod.image,
            orderid: order.orderid,
            customer: order.buyer,
          });
        });
      }
    });

    var total = 0;
    var quantity = 0;
    var quantityReq = 0;
    props.products.map(function (item) {
      total = total + item.price * item.quantity_assigned;
      quantity = quantity + item.quantity_assigned;
      quantityReq = quantityReq + item.quantity;
    });
    //setTotal(total);
    setQuantity(quantity);
    setQuantityReq(quantityReq);
    setQuantity(quantity);
    setProducts(products);
    setDedications(dedications);
  }, [props]);

  const toggle = (nr) => {
    if (nr === 1) {
      setModal1(!modal1);
    } else if (nr === 2) {
      if (modal2) {
        document.body.style.overflow = "auto";
      } else {
        document.body.style.overflow = "hidden";
        const fetchFn = async () => {
          setIsloading(true);
          let form = await callApi(
            "layaoutManager",
            "getLayoutTemplateByName",
            {
              layoutFile: "setExternalOrderForm_" + getLanguage(),
              foldername: foldername + "private/",
            }
          );
          /*
          let rendegObj = await getRenderObj(
            form.template[0],
            null,
            null,
            null,
            null,
            null,
            null,
            null
          );
		  */
          //setExternalOrderFormData(rendegObj.curdata.data);
          //setExternalOrderFormConfig(rendegObj.curdata.config);
          //console.log(rendegObj.curdata)
          setIsloading(false);
        };
        fetchFn();
      }
      setModal2(!modal2);
    }
  };

  const handleChange = (event) => {
    var sku = event.target.value;
    setSku(sku);
    if (event.key === "Enter") {
      addSKU(event);
    }
  };
  /*
  const handleChangeSKU = (event) => {
    var sku = event.target.value;
    setSkuToAdd(sku);
  };
  const changeHandlerD = (event) => {
    //var formData =  changeHandler ( event, externalOrderFormData )
    //setExternalOrderFormData(formData)
    let _externalOrderFormData = externalOrderFormData;

    _externalOrderFormData["firstName"].value = event.target.value;
    setExternalOrderFormData(_externalOrderFormData);
 
  };
  */

  const addSKU = async (event) => {
    setIsloading(true);
    var formData = {
      sku: sku,
      productid: 0,
      eventid: props.eventid,
      quantity: 1,
    };
    var resp = await callApi("frontend", "setProductEvent", formData);
    if (resp.success) {
      //setModal1 (false)
      showMessage(toast, getDefValues().successfullyAddedToEventListLabel);
      await props.refreshEventProducts();
    } else {
      showError(toast, getDefValues().errorAddedToEventListLabel);
    }
    setIsloading(false);
  };
  /*
  const registerOrder = async (event) => {
    setIsloading(true);

    var formData = {
      skus,
      eventid: props.eventid,
      storeid,
      sendStore: storeStatus ? "YES" : "NO",
      skus,
      orderinfo: props.eventInfo,
      dedicationName: dedicationName,
      dedicationMessage: dedicationMessage,
    };
    //console.log(formData)
    var resp = await callApi("frontend", "setExternalOrders", formData);
    if (resp.success) {
      showMessage(toast, getDefValues().successfullyOrderRegisteredLabel);
      await props.refreshEventProducts();
      setModal2(false);
      setSkuToAdd("");
      setDedicationName("");
      setDedicationMessage("");
      setSkus([]);
    } else {
      showError(toast, getDefValues().errorOrderRegisteredLabel);
    }
    setIsloading(false);
  };

  const delSku = async (sku) => {
    let newSkus = skus;
    let pos = find(skus, sku, "productsku");
    newSkus.splice(pos, 1);
    setSkus(newSkus);
  };
  const addSKUToOrder = async (event) => {
    let newSkus = skus;
    let pos1 = find(props.products, skuToAdd, "productsku");
    let pos2 = find(newSkus, skuToAdd, "productsku");

    let skuData = props.products[pos1];

    //console.log(skuData)

    if (pos2 < 0) {
      let maxQty = skuData.quantity - skuData.quantity_assigned;
      if (maxQty > 0) {
        let newSku = {
          productid: skuData.productid,
          productsku: skuData.productsku,
          image: skuData.image,
          quantity: 1,
          quantity_assigned: skuData.quantity_assigned,
        };
        newSkus.push(newSku);
      } else {
        showError(toast, getDefValues().skuExceededMessage);
      }
    } else {
      let currSkuData = newSkus[pos2];
      let currQty = currSkuData.quantity + 1;
      let maxQty = skuData.quantity - skuData.quantity_assigned;

      if (currQty <= maxQty) {
        newSkus[pos2]["quantity"] = currQty;
      } else {
        showError(toast, getDefValues().skuExceededMessage);
      }
    }
    setSkus(newSkus);
  };
*/
  return (
    <>
      <Modal
        height={products.length > 0 ? windowHeight - 180 : 150}
        minHeight={products.length > 0 ? 450 : 150}
        lg={products.length > 0 ? true : false}
        overflowY={products.length > 0 ? "scroll" : "hidden"}
        top={products.length > 0 ? true : false}
        content={
          products.length === 0 ? (
            <div
              style={{ height: 140 }}
              className="row flex middle text-center"
            >
              <div className="col-12">
                <h3> {getDefValues().nodedicationLabel} </h3>
              </div>
            </div>
          ) : (
            <table className="table shopping-summery clean">
              <thead>
                <tr className="main-heading">
                  {/*<th className="text-left" scope="col"><span className="ml-2"> {getDefValues().shopCartProductLabel.toUpperCase()} </span> </th>
										<th className="text-left" scope="col"></th> 
										<th className="text-left" scope="col" >{getDefValues().orderLabel.toUpperCase()}</th>
										*/}
                  <th className="text-left" scope="col">
                    Lista de dedicatorias
                  </th>
                </tr>
              </thead>

              <tbody>
                {dedications.length === 0 ? (
                  <tr>
                    <td
                      style={{ wordWrap: "anywhere" }}
                      className="text-justify"
                      data-title={getDefValues().customerLabel.toUpperCase()}
                    >
                      <span
                        style={{
                          marginLeft: 8,
                          fontSize: "1rem",
                          fontStyle: "oblique",
                          lineHeight: "1rem",
                        }}
                      >
                        {getDefValues().nodedicationslabel}{" "}
                      </span>
                    </td>
                  </tr>
                ) : (
                  <>
                    {dedications.map(
                      (item) =>
                        item.message &&
                        item.message !== "" && (
                          <tr>
                            <td
                              style={{ wordWrap: "anywhere" }}
                              className="text-justify"
                              data-title={getDefValues().customerLabel.toUpperCase()}
                            >
                              <p
                                style={{
                                  margin: 0,
                                  textTransform: "capitalize",
                                  fontWeight: "700",
                                  fontSize: "1.1rem",
                                  lineHeight: "1rem",
                                }}
                              >
                                {" "}
                                {item.name}{" "}
                              </p>
                              <span
                                style={{
                                  marginLeft: 8,
                                  fontSize: "1rem",
                                  fontStyle: "oblique",
                                  lineHeight: "1rem",
                                }}
                              >
                                {" "}
                                {item.message}{" "}
                              </span>
                            </td>
                          </tr>
                        )
                    )}
                  </>
                )}

                {1 === 0 &&
                  products.map((item) => (
                    <tr>
                      {/*
								<td className="image product-thumbnail text-left">
										<img alt={item.name}  src={getImagePath(item.image)} className="card-img-top" />
								</td>
								<td className="product-des product-name text-left">
									<div className="" >
										<div  >
												<span >{item.name}</span>
										</div>
									</div>
								</td>			
																			 
								<td  data-title={getDefValues().orderLabel.toUpperCase()}>
									<div className="flex col-text-quantity">
									 <span className="text-bold text-underline">{item.orderid}</span>
									</div>
								</td>
								*/}
                      <td
                        style={{ wordWrap: "anywhere" }}
                        className="text-left"
                        data-title={getDefValues().customerLabel.toUpperCase()}
                      >
                        <span> {item.customer} </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )
        }
        staticBackdrop={true}
        toogleCloseButton={true}
        hideCloseButton={true}
        toggleModal={toggleShowComment}
        modal={showDForm}
      />

      <PageLoading isLoading={isloading} />

      <MDBModal
        isOpen={modal1}
        toggle={() => toggle(1)}
        fade
        centered
        className="fullheigth-modal"
      >
        <MDBModalBody>
          <p className="flex items-left mt-3">
            <span className="h3-responsive">
              {" "}
              {getDefValues().addSKUtoEventLabel}{" "}
            </span>
          </p>
          <span className="or-line"></span>
          <div className="row mt-50 mb-30">
            <div className="col-12 flex items-right">
              <input
                className="pagination-search-input"
                value={sku}
                onChange={handleChange}
                onKeyDown={handleChange}
                type="text"
                name="sku"
                placeholder={getDefValues().searchProductLabel}
              />
              <button
                className={"btn btn-secondary btn-sm ml-1"}
                type="button"
                style={{ width: "25%" }}
                onClick={addSKU}
              >
                {getDefValues().shortbtnaddbutton}
              </button>
            </div>
          </div>
        </MDBModalBody>
      </MDBModal>

      <div className="row mt-2">
        <div className="col-md-12 col-lg-12">
          <div className="card card-table flex-fill">
            <>
              <div className="shop-cart">
                <div>
                  <img src={wishlist} alt="shop-cart" />
                </div>
                <h5 className="header-left ">
                  {getDefValues().eventListTitleLabel.toUpperCase()}
                </h5>
              </div>

              <h6 className="shop-cart-h6 mt-1 mb-1">
                {getDefValues().productEventListLabel}{" "}
              </h6>

              <div className="row mt-2">
                <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                  {props.products && props.products.length === 0 ? (
                    <h5 className="mt-30 mb-30">
                      {!props.isLoading &&
                        getDefValues().notEventProductsMessage}
                    </h5>
                  ) : (
                    <table className="table shopping-summery clean">
                      <thead>
                        <tr className="main-heading">
                          <th scope="col">
                            <span className="ml-2">
                              {" "}
                              {getDefValues().shopCartProductLabel}{" "}
                            </span>{" "}
                          </th>
                          <th scope="col"></th>
                          <th scope="col">
                            {getDefValues().shopCartPriceLabel}
                          </th>
                          <th scope="col" style={{ paddingRight: "0.5rem" }}>
                            {getDefValues().shopCartQuantityRequestedLabel}
                          </th>

                          <th scope="col" style={{ paddingRight: "0.5rem" }}>
                            {getDefValues().shopCartQuantityAssignedLabel}
                          </th>

                          {/**	<th scope="col" >{getDefValues().shopCartSubtotalLabel}</th> */}
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.products.map((item) => (
                          <>
                            <tr>
                              <td className="image product-thumbnail">
                                <Link
                                  to={
                                    (item.category
                                      ? "/" + item.category + "/"
                                      : "/productDetails/") +
                                    (item.productpath
                                      ? item.productpath
                                      : item.productname)
                                  }
                                >
                                  <img
                                    alt={item.productname}
                                    src={getImagePath(item.image)}
                                    className="card-img-top"
                                  />
                                </Link>
                              </td>
                              <td className="product-des product-name">
                                <div className="">
                                  <div className="cart-product-name">
                                    <Link
                                      to={
                                        (item.category
                                          ? "/" + item.category + "/"
                                          : "/productDetails/") +
                                        (item.productpath
                                          ? item.productpath
                                          : item.productname)
                                      }
                                    >
                                      <span className="text-bold text-underline">
                                        {item.productname}
                                      </span>
                                    </Link>

                                    <p className="mb-1">
                                      <span className="">
                                        {(item.packname
                                          ? getDefValues().packRefLabel + ": "
                                          : "SKU: ") + item.productsku}
                                      </span>
                                    </p>
                                    {item.tagid === 1 && (
                                      <p className="mb-1">
                                        <span
                                          style={{ minWidth: 40 }}
                                          className={
                                            "h5-responsive badge rounded-pill badge-secondary"
                                          }
                                        >
                                          {" "}
                                          <i className="fas fa-gift"></i>{" "}
                                          {getDefValues().extragiftLabel}{" "}
                                        </span>
                                      </p>
                                    )}

                                    {item.maxQuantity === 0 ? (
                                      <p className="mb-1">
                                        <span
                                          style={{ minWidth: 40 }}
                                          className={
                                            "h5-responsive badge rounded-pill badge-danger"
                                          }
                                        >
                                          {" "}
                                          <i className="fas fa-exclamation"></i>{" "}
                                          {getDefValues().outInventoryLabel}{" "}
                                        </span>
                                      </p>
                                    ) : (
                                      <>
                                        {item.maxQuantity < item.quantity && (
                                          <p className="mb-1">
                                            <span
                                              style={{ minWidth: 40 }}
                                              className={
                                                "h5-responsive badge rounded-pill badge-danger"
                                              }
                                            >
                                              {" "}
                                              <i className="fas fa-exclamation"></i>{" "}
                                              {
                                                getDefValues()
                                                  .outInventoryLabel1
                                              }{" "}
                                              {
                                                getDefValues()
                                                  .outInventoryLabel2
                                              }{" "}
                                              {item.maxQuantity}{" "}
                                            </span>
                                          </p>
                                        )}
                                      </>
                                    )}

                                    {/*!item.commentsku!= getDefValues().defCommentEventProdut && !item.showComment?
																				<button name={ "showComment__"+item.productsku }	onClick={props.updateEventList}  className="btn btn-xs btn-secondary">
																					{getDefValues().addCommentLabel.toUpperCase()}
																				</button>
																				:""*/}

                                    {item.orders.length > 0 ? (
                                      <>
                                        <div className="row">
                                          <div className="col-12">
                                            {getDefValues().boughtByLabel.toUpperCase()}
                                          </div>
                                        </div>
                                        {item.orders.map((item, index) => (
                                          <div className="row">
                                            <div className="col-12">
                                              {item.buyer
                                                .substr(
                                                  0,
                                                  item.buyer.indexOf(":")
                                                )
                                                .toUpperCase()}
                                              {item.deliverytype ===
                                                "EXTERNALDELIVERY" && (
                                                <span
                                                  style={{
                                                    color: "red",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {" "}
                                                  {
                                                    getDefValues()
                                                      .externaldeliverylabel
                                                  }{" "}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td
                                className="col-text-right"
                                data-title={getDefValues().shopCartPriceLabel.toUpperCase()}
                              >
                                <span className="cart-price">
                                  {getCurrency()}
                                  {toFixed(item.price)}
                                </span>
                              </td>
                              <td
                                data-title={getDefValues().shopCartQuantityRequestedLabel.toUpperCase()}
                              >
                                <div className="flex col-text-quantity">
                                  <div className="detail-qty-shopcart">
                                    <button
                                      name={"decrease__" + item.productsku}
                                      onClick={props.updateEventList}
                                      className="qty-down"
                                    >
                                      <i className="fas fa-minus"></i>
                                    </button>
                                    <input
                                      type="text"
                                      className="qty-val"
                                      name={"quantity__" + item.productsku}
                                      value={
                                        item.prequantity != null
                                          ? item.prequantity
                                          : item.quantity
                                      }
                                      onChange={props.updateEventList}
                                    />
                                    <button
                                      name={"increase__" + item.productsku}
                                      onClick={props.updateEventList}
                                      className="qty-up"
                                    >
                                      <i className="fas fa-plus"></i>
                                    </button>
                                  </div>
                                  {item.showUpdate ? (
                                    <button
                                      name={"update__" + item.productsku}
                                      onClick={props.updateEventList}
                                      className="confirm-upd"
                                    >
                                      <i className="fas fa-sync-alt"></i>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                              <td
                                className="col-text-right"
                                data-title={getDefValues().shopCartQuantityAssignedLabel.toUpperCase()}
                              >
                                <span
                                  style={{ minWidth: 40 }}
                                  className={
                                    "h5-responsive badge rounded-pill  " +
                                    (item.quantity_assigned >= item.quantity
                                      ? " badge-success"
                                      : " badge-warning")
                                  }
                                >
                                  {" "}
                                  {item.quantity_assigned}{" "}
                                  {item.quantity_assigned >= item.quantity ? (
                                    <i className="fas fa-check-circle"></i>
                                  ) : (
                                    ""
                                  )}{" "}
                                </span>
                              </td>
                              {/*
																<td className="col-text-right" data-title={getDefValues().shopCartSubtotalLabel.toUpperCase()}>
																		<span>{getCurrency()}{  toFixed(item.price*item.quantity) }</span>																 
																</td>
																*/}
                              <td
                                className="col-text-center action"
                                data-title=""
                              >
                                <a
                                  name={"del__" + item.productsku}
                                  onClick={props.updateEventList}
                                  className="text-muted"
                                >
                                  <img
                                    className="icon-trash"
                                    src={getImagePath(
                                      "/catalog/images/ico_trash.svg"
                                    )}
                                    alt="delete-product"
                                    width="28px"
                                    height="29px"
                                  />
                                </a>
                              </td>
                            </tr>
                            {/*item.commentsku!= getDefValues().defCommentEventProdut || item.showComment?
																<tr style={{ borderTop:0, borderBottom: "1px solid #e2e5e8" }}>
																	<td className="image product-thumbnail" style={{ borderTop:"1px solid #fff" , paddingTop: 0}}>
																	</td>
																	<td  colspan="6" style={{ borderTop:"1px solid #fff", paddingTop: 0}} > 
																		<div className="event-sku-comment">
																			<textarea  
																				name={ "commentsku__"+item.productsku }
																				value={item.commentsku+""} 
																				onChange={props.updateEventList} />
																			<div>	
																				<button name={ "saveComment__"+item.productsku }	onClick={props.updateEventList}  className="btn btn-xs btn-secondary">
																						{getDefValues().saveLabel.toUpperCase()}
																				</button>	 		
																			</div>
																		</div>
																	</td>
																</tr>
															:""*/}
                          </>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
                <div className=" col-sm-12 col-md-12 col-lg-3 col-xl-3 ">
                  <div style={props.resumeStyle}>
                    <div className="mb-1 background-resume-order-2">
                      {/*
															<div className="row">
																<div className="col-12 resume-order-title">
																	<span className="h5-responsive">{getDefValues().eventResumeLabel}</span>
																</div> 
															</div>
															
															<div className="row">
																<div className="col-12 flex items-right text-right">																	 
																		<b>{getCurrency()} {toFixed(total)}</b>																	 
																</div>
															</div>	
															*/}
                      <div className="row">
                        <div className="col-12 resume-order-title">
                          <span className="h5-responsive">
                            {getDefValues().eventResumeQuantityLabel}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 flex items-right text-right">
                          <b>
                            {quantity} {getDefValues().ofLabel} {quantityReq}{" "}
                            {getDefValues().giftLabel}
                          </b>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-1" style={{ margin:0 }}>
                      <div className="col-12">
                        <button
                          onClick={toggleShowComment}
                          className="btn btn-secondary btn-block"
                        >
                          {getDefValues().showDedicationsLabel.toUpperCase()}
                        </button>
                      </div>
                    </div>

                    {props.token ? (
                      <div className="row mb-1"  style={{ margin:0 }}>
                        <div className="col-12">
                          <button
                            onClick={() => toggle(1)}
                            className="btn btn-secondary btn-block"
                          >
                            {getDefValues().addSkusLabel.toUpperCase()}
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {props.token ? (
                      <div className="row mb-1" style={{ margin:0 }}>
                        <div className="col-12">
                          <button
                            onClick={props.toggle}
                            className="btn btn-secondary btn-block"
                          >
                            {getDefValues().registerOrderLabel.toUpperCase()}
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="row mb-1"  style={{ margin:0 }}>
                      <div className="col-12">
                        <button
                          onClick={() => {
                            history.push("/");
                          }}
                          className="btn btn-secondary btn-block"
                        >
                          {getDefValues().continueEventLabel.toUpperCase()}
                        </button>
                      </div>
                    </div>
                    <div className="row mb-1"  style={{ margin:0 }}>
                      <div className="col-12">
                        <button
                          onClick={props.getTokenEventlist}
                          className="btn btn-secondary btn-block"
                        >
                          {getDefValues().sendListLabel.toUpperCase()}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};
LoadList.propTypes = {};
export default LoadList;
