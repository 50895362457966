/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { getImagePath } from "../../common/functions";

const windowWidthDef = window.innerWidth;
const loadCaracol = (props) => { 
  if (props) {
    var show = true;

    if (show) {
      return windowWidthDef < 799 ? (
        <div
          className="image-gallery-mobile"
          style={{
            marginTop: props.caracolData.margintop ? props.caracolData.margintop : 0,
            marginBottom: props.caracolData.marginbottom ? props.caracolData.marginbottom : 0,
          }}
        >
          <div className="image image-1">
            <a
              href={props.caracolConfig.link1}
              target={props.caracolConfig.targetlink1}
            >
              <img
                src={getImagePath(props.caracolData.imagemobile1)}
                alt={props.caracolData.text1}
              />
            </a>
          </div>
          <div className="image image-2">
            <a
              href={props.caracolConfig.link2}
              target={props.caracolConfig.targetlink2}
            >
              <img
                src={getImagePath(props.caracolData.imagemobile2)}
                alt={props.caracolData.text2}
              />
            </a>
          </div>
          <div className="image image-3">
            <a
              href={props.caracolConfig.link3}
              target={props.caracolConfig.targetlink3}
            >
              <img
                src={getImagePath(props.caracolData.imagemobile3)}
                alt={props.caracolData.text3}
              />
            </a>
          </div>
          <div className="image image-4">
            <a
              href={props.caracolConfig.link4}
              target={props.caracolConfig.targetlink4}
            >
              <img
                src={getImagePath(props.caracolData.imagemobile4)}
                alt={props.caracolData.text4}
              />
            </a>
          </div>
          <div className="image image-5">
            <a
              href={props.caracolConfig.link5}
              target={props.caracolConfig.targetlink5}
            >
              <img
                src={getImagePath(props.caracolData.imagemobile5)}
                alt={props.caracolData.text5}
              />
            </a>
          </div>
          <div className="image image-6">
            <a
              href={props.caracolConfig.link6}
              target={props.caracolConfig.targetlink6}
            >
              <img
                src={getImagePath(props.caracolData.imagemobile6)}
                alt={props.caracolData.text6}
              />
            </a>
          </div>
          <div className="image image-7">
            <a
              href={props.caracolConfig.link7}
              target={props.caracolConfig.targetlink7}
            >
              <img
                src={getImagePath(props.caracolData.imagemobile7)}
                alt={props.caracolData.text7}
              />
            </a>
          </div>
        </div>
      ) : (
        <div className="image-gallery"
        
        style={{
          marginTop: props.caracolData.margintop ? props.caracolData.margintop+"px" : 0,
          marginBottom: props.caracolData.marginbottom ? props.caracolData.marginbottom+"px" : 0,
        }}

        >
          <div className="image image-1">
            <a
              href={props.caracolConfig.link1}
              target={props.caracolConfig.targetlink1}
            >
              <img
                src={getImagePath(props.caracolData.image1)}
                alt={props.caracolData.text1}
              />
            </a>
          </div>
          <div className="image image-2">
            <a
              href={props.caracolConfig.link2}
              target={props.caracolConfig.targetlink2}
            >
              <img
                src={getImagePath(props.caracolData.image2)}
                alt={props.caracolData.text2}
              />
            </a>
          </div>
          <div className="image image-3">
            <a
              href={props.caracolConfig.link3}
              target={props.caracolConfig.targetlink3}
            >
              <img
                src={getImagePath(props.caracolData.image3)}
                alt={props.caracolData.text3}
              />
            </a>
          </div>
          <div className="image image-4">
            <a
              href={props.caracolConfig.link4}
              target={props.caracolConfig.targetlink4}
            >
              <img
                src={getImagePath(props.caracolData.image4)}
                alt={props.caracolData.text4}
              />
            </a>
          </div>
          <div className="image image-5">
            <a
              href={props.caracolConfig.link5}
              target={props.caracolConfig.targetlink5}
            >
              <img
                src={getImagePath(props.caracolData.image5)}
                alt={props.caracolData.text5}
              />
            </a>
          </div>
          <div className="image image-6">
            <a
              href={props.caracolConfig.link6}
              target={props.caracolConfig.targetlink6}
            >
              <img
                src={getImagePath(props.caracolData.image6)}
                alt={props.caracolData.text6}
              />
            </a>
          </div>
          <div className="image image-7">
            <a
              href={props.caracolConfig.link7}
              target={props.caracolConfig.targetlink7}
            >
              <img
                src={getImagePath(props.caracolData.image7)}
                alt={props.caracolData.text7}
              />
            </a>
          </div>
        </div>
      );
    } else {
      return "";
    }
  } else {
    return "NO TEXT DATA";
  }
};
export default loadCaracol;
