/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/*****components***/
import React, { Component } from "react";

import { Redirect } from "react-router-dom";
/*custom components*/
import PageLoading from "../../components/PageLoading";

import Modal from "../../components/ModalV2";
import Form from "../../components/Form";
import Card from "../../components/Card";
import Caracol from "../../components/Caracol";
import Slider from "../../components/Slider";
import SliderImage from "../../components/SliderImage";
import Text from "../../components/Text";
import Button from "../../components/Button";
import Image from "../../components/Image";
import Video from "../../components/Video";
import EventMessage from "../../components/EventMessage";

import Login from "../../components/Login";
import Map from "../../components/Map";

import {
  toast,
  MDBAlert,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBBtn,
} from "mdbreact";
import ico_login from "../../assets/images/ico_login.svg";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
/*functions*/
import {
  updateEventList,
  manageCart,
  manageButton,
  getCookie,
  setCookie,
  shareEvent,
  formatPathName,
  getImagePath,
  managepagination,
  loginSN,
  getRenderObj,
  login,
  getDefValues,
  getLanguage,
  managesort,
  findObjName,
  getSyncStoresInfo,
  find,
  getLoginInfo,
  changeHandler,
  changeHandlerManager,
  toggleModalManager,
  loadPageV2,
  mergeFormData,
  callApi,
  validateForm,
  showError,
  showMessage,
  showWarning,
  parseFormData,
} from "../../common/functions";
import EventProductsInfo from "../../components/EventProductsInfo";
import { BitlyClient } from "bitly-react";
import { withScriptjs } from "react-google-maps";
var windowWidth = window.innerWidth;
const foldername = process.env.REACT_APP_FOLDER_LAYOUT;
const GoogleMapsAPI = process.env.REACT_APP_API_KEY_GMAP;
class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      lockform: true,
      storeStatus: "NO",
      messages: [],
      noEvents: false,
      needLogin: true,
      formstep: 1,
      isLoading2: false,
      eventmessage: "",
      eventname: "",
      //city: "La Paz",
      skus: [],
      skuToAdd: "",
      eventimage: "",
      mainClass: "page-wrapper",
      redirectProductDetails: false,
      selectedProductID: 0,
      externalOrderFormConfig: {},
      externalOrderFormData: {},
      selectedPath: "",
      selectedCategory: "",
      modal2: false,
      isCopied: false,
      shareUrl: "",
      eventInfo: {},
      eventListInfo: { products: [], needCommit: false },
      formData: {
        email: {
          label: getDefValues().emailLabel,
          value: "",
          type: "INPUT",
          validationtype: "email",
        },
        password: {
          label: getDefValues().passwordLabel,
          value: "",
          type: "PASSWORD",
          validationtype: "PASSWORD",
        },
      },
      latitude: -16.5337955,
      longitude: -68.0865515,
      address: "",
      city: "",
      showEvents: false,
      externalOrder: false,
      externalOrderId: 0,
    };
  }

  showExternalOrder = (event) => {
    if (this.state.externalOrder) {
      this.setState({ ...this.state, externalOrder: false });
    } else {
      this.setState({ ...this.state, externalOrder: true });
    }
  };
  /*loading data*/
  componentDidMount = async () => {
    try {
      this.props.hideCart();

      var scrollCheck = window.scrollY;
      var resumeStyle = {
        position: "relative",
        rigth: "0px",
        top: scrollCheck,
        background: "#fff",
        zIndex: "10",
      };
      if (window.innerWidth <= 799) {
        resumeStyle = {};
      }
      this.setState({ resumeStyle: resumeStyle, scrollCheck: scrollCheck });

      var resp1;
      var resp2;
      var eventid = 0;
      var login = {};
      var noToken = true;
      var token;
      var selectedEventId = 0;
      var formstep = 1;

      var eventCookieId = getCookie(
        process.env.REACT_APP_ORG_NAME + "_eventId"
      );
      var haveParam = false;

      var eventToken = getCookie(
        process.env.REACT_APP_ORG_NAME + "_eventToken"
      );
      //console.log("eventToken", eventToken)
      if (this.props.match.params.key || eventToken) {
        if (this.props?.match?.params?.key?.indexOf("addProducts") >= 0) {
          selectedEventId = parseInt(
            this.props.match.params.key.replace("addProducts_", "")
          );
          formstep = 2;
          login = getLoginInfo();
        } else {
          //console.log("es token admin")
          haveParam = true;
          token = this.props.match?.params?.key;
          if (token) {
            //console.log("token de la url, set cookie")
            setCookie(
              process.env.REACT_APP_ORG_NAME + "_eventToken",
              token,
              0.5
            );
          } else {
            //console.log("token de la cookie", eventToken)
            token = eventToken;
          }
          resp1 = await callApi("frontend", "decodePrivateToken", {
            token: token,
          });
          //console.log("resp t", resp1)
          if (resp1.success) {
            this.setState({ ...this.state, token: resp1.tokenData });
            login = resp1.tokenData;
            eventid = resp1.tokenData.eventid;
          }
          noToken = false;
          formstep = 2;
        }
      }

      if (eventCookieId && !haveParam) {
        if (
          eventCookieId != "0" &&
          eventCookieId != "null" &&
          eventCookieId != ""
        ) {
          selectedEventId = parseInt(eventCookieId);
          /*
						let moreEvents = false;
						login = getLoginInfo()
						let resp1 = await callApi ( "frontend","getCustomerEventsByUserId", {userid:login.userId } )
						if (resp1.EVENTS.length>1){				 
							moreEvents=true											
						}
					*/
          formstep = 2;
        }
      }

      try {
        if (window.location.search.indexOf("step=info") >= 0) {
          formstep = 1;
        } else if (window.location.search.indexOf("step=list") >= 0) {
          formstep = 2;
        }
      } catch (Ex) {
        console.log("EX", Ex);
      }

      this.setState({ formstep: formstep });

      var showEvents = false;
      var moreEvents = false;
      var events = [];
      if (noToken) {
        login = getLoginInfo();
        resp1 = await callApi("frontend", "getCustomerEventsByUserId", {
          userid: login.userId,
        });

        if (resp1.EVENTS.length > 1) {
          moreEvents = true;
        }

        if (selectedEventId > 0) {
          eventid = selectedEventId;
          //resp1 = await callApi ( "frontend","getCustomerEventsByUserId", {userid:login.userId } )

          for (let e in resp1.EVENTS) {
            let eventD = resp1.EVENTS[e];
            eventD["EVENTINFO"] = JSON.parse(eventD["EVENTINFO"]);
            events.push(eventD);
          }
        } else {
          if (resp1.EVENTS.length > 1) {
            showEvents = true;

            for (let e in resp1.EVENTS) {
              let eventD = resp1.EVENTS[e];
              eventD["EVENTINFO"] = JSON.parse(eventD["EVENTINFO"]);
              events.push(eventD);
            }
          }

          if (resp1.EVENTS.length > 0) {
            eventid = resp1.EVENTS[0].EVENTID;
          }
        }
      }

      resp2 = await callApi("frontend", "getCustomerEventById", {
        languageid: getLanguage(),
        eventid: eventid,
      });
      //console.log("resp2", resp2,  noToken)
      if (login.userId) {
        //var finalFoldername=foldername
        var layoutFile = "createEventForm_" + getLanguage();
        var resp = await callApi("layaoutManager", "getLayoutTemplateByName", {
          layoutFile: layoutFile,
          foldername: foldername + "private/",
        });

        if (resp2.success) {
          let storesInfo = getSyncStoresInfo();
          if (storesInfo.selectedStoreId !== resp2.EVENT.STOREID) {
            this.props.setStoreOff(resp2.EVENT.STOREID);
          }

          let form = await callApi(
            "layaoutManager",
            "getLayoutTemplateByName",
            {
              layoutFile: "setExternalOrderForm_" + getLanguage(),
              foldername: foldername + "private/",
            }
          );
          let rendegObj = await getRenderObj(
            form.template[0],
            null,
            null,
            null,
            null,
            null,
            null,
            null
          );

          let externalOrderFormData = rendegObj.curdata.data;
          let externalOrderFormConfig = rendegObj.curdata.config;

          let qty = 0;
          // eslint-disable-next-line array-callback-return
          resp2.PRODUCTS.map(function (item) {
            qty = qty + item.quantity;
          });

          //this.props.setEventMode(eventid, resp2.EVENT.EVENTINFO.eventname, 'addEventList', qty)
          if (!moreEvents) {
            await this.props.setEventMode(
              eventid,
              resp2.EVENT.EVENTINFO.eventname,
              "addEventList",
              qty
            );
          }

          //let resp4 = await callApi  ( "admin","getCustomerById"   , {   customerid: login.userId } )
          //this.setState({...this.state,  userDataInfo : resp4.USER, userAddInfo: resp4.ADDRESS });

          var _city = (resp2.EVENT.EVENTINFO.city).toString();
          var city = "";

          if (_city === "1") {
            city = "La Paz";
          }
          if (_city === "2") {
            city = "Cochabamba";
          }
          if (_city === "3") {
            city = "Santa Cruz";
          }
          if (_city === "4") {
            city = "Tarija";
          }
          if (_city === "5") {
            city = "Sucre";
          }
          if (_city === "6") {
            city = "Potos\u00ED";
          }
          if (_city === "7") {
            city = "Oruro";
          }
          if (_city === "8") {
            city = "Cobija";
          }
          if (_city === "9") {
            city = "Beni";
          }

          let eventInfo = resp2.EVENT;
          let info = resp2.EVENT.EVENTINFO;
          Object.assign(eventInfo, info);

          var _products = resp2.PRODUCTS;
          _products.map(function (item) {
            if (!item.commentsku) {
              item["commentsku"] = getDefValues().defCommentEventProdut;
            }
          });

          //	console.log("eventsF", resp2.EVENT.CUSTOMERINFO[0].firstname)
          this.setState({
            ...this.state,
            eventListInfo: { products: resp2.PRODUCTS },
            eventid: eventid,
            events: events,
            city: city,
            showEvents: showEvents,
            moreEvents: moreEvents,
            externalOrderFormData: externalOrderFormData,
            externalOrderFormConfig: externalOrderFormConfig,
            eventimage: resp2.EVENT.IMAGEPATH,
            eventdate: resp2.EVENT.EVENTDATE,
            eventperson: resp2.EVENT.CUSTOMERINFO[0].firstname,
            eventdate2: resp2.EVENT.EVENTDATE2,
            eventmessage: resp2.EVENT.EVENTINFO.message,
            eventname: resp2.EVENT.EVENTINFO.eventname,
            eventInfo: eventInfo,
            userId: resp2.EVENT.CUSTOMERID,
            storeid: resp2.EVENT.STOREID,
            token: token,
          });
          let _pageData = await loadPageV2(
            resp.template,
            this.managepagination,
            this.manageCart,
            this.changeHandler,
            this.manageButton,
            this.toggleModal,
            windowWidth
          );
          this.setState({ template: resp.template });
          this.renderData(_pageData);

          layoutFile = "suggestedCategories_" + getLanguage();

          try {
            let evenInfo = JSON.parse(resp2.EVENT.EVENT_FORMAT);
            layoutFile = evenInfo.pathlayout;
          } catch (EX) {
            console.log("no layout founded");
          }

          resp = await callApi("layaoutManager", "getLayoutTemplateByName", {
            layoutFile: layoutFile,
            foldername: foldername,
          });
		  /*
          var template = resp.template;
          for (let t in template) {
            let config = template[t].config;
            let data = template[t].data;
          }*/
          let _pageData2 = await loadPageV2(
            resp.template,
            this.managepagination,
            this.manageCart,
            this.changeHandler,
            this.manageButton,
            this.toggleModal,
            windowWidth
          );
          this.setState({ template2: resp.template });
          this.renderData2(_pageData2);
        } else {
          //console.log("estado noton",noToken)
          if (!noToken) {
            this.setState({ isLoading: false, invalidEvent: true });
          } else {
            this.setState({
              isLoading: false,
              noEvents: true,
              messages: [
                getDefValues().notEventFoundsubTitleLabel1,
                getDefValues().notEventFoundsubTitleLabel2,
              ],
            });
          }
        }
      } else {
        this.setState({ modal1: true, isLoading: false });
      }

      document.addEventListener("scroll", this.handleResize);
    } catch (err) {
      console.log(">>>>>>>>>>", err);
    } finally {
      this.setState({ isLoading: false });
    }
    //window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleResize);
  }

  handleResize = async () => {
    var scrollCheck = window.scrollY;

    let maxSize = this.state.eventListInfo.products.length * 100 - 260;
    if (maxSize < 301) {
      maxSize = 301;
    }
    if (scrollCheck > 301) {
      scrollCheck = scrollCheck - 301;

      if (scrollCheck > maxSize) {
        scrollCheck = maxSize;
      }
    } else {
      scrollCheck = 0;
    }

    if (this.state.eventListInfo.products.length <= 3) {
      scrollCheck = 0;
    }

    //console.log("finl",scrollCheck)

    var resumeStyle = {
      position: "relative",
      rigth: "0px",
      top: scrollCheck,
      background: "#fff",
      zIndex: "999",
    };
    if (window.innerWidth <= 799) {
      resumeStyle = {};
    }
    this.setState({
      scrollCheck: scrollCheck,
      resumeStyle: resumeStyle,
      windowHeight: window.innerHeight,
    });
  };

  setEventById = async (eventid) => {
    let fields = [];
    for (let i in Object.keys(this.state)) {
      let field = Object.keys(this.state)[i];
      if (field.indexOf("2RENDEROBJ") >= 0) {
        //console.log("borrar", this.state[field] )
        let obj = this.state[field];
        obj["type"] = "NULL";
        fields.push(field);
        this.setState({ ...this.state, [field]: obj });
        //delete this.state[field]
      }
    }

    for (let f in fields) {
      this.setState({ ...this.state, [fields[f]]: null });
      //delete this.state[fields[f]]
    }

    this.setState({ ...this.state, showEvents: false, isLoading: true });
    //var finalFoldername = foldername;
    var layoutFile = "createEventForm_" + getLanguage();
    var resp = await callApi("layaoutManager", "getLayoutTemplateByName", {
      layoutFile: layoutFile,
      foldername: foldername + "private/",
    });

    var resp2 = await callApi("frontend", "getCustomerEventById", {
      languageid: getLanguage(),
      eventid: eventid,
    });

    if (resp2.success) {
      let storesInfo = getSyncStoresInfo();
      if (storesInfo.selectedStoreId != resp2.EVENT.STOREID) {
        this.props.setStoreOff(resp2.EVENT.STOREID);
      }

      let form = await callApi("layaoutManager", "getLayoutTemplateByName", {
        layoutFile: "setExternalOrderForm_" + getLanguage(),
        foldername: foldername + "private/",
      });
      let rendegObj = await getRenderObj(
        form.template[0],
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );

      let externalOrderFormData = rendegObj.curdata.data;
      let externalOrderFormConfig = rendegObj.curdata.config;
      let qty = 0;
      resp2.PRODUCTS.map(function (item) {
        qty = qty + item.quantity;
      });
      this.props.setEventMode(
        eventid,
        resp2.EVENT.EVENTINFO.eventname,
        "addEventList",
        qty
      );

      let eventInfo = resp2.EVENT;
      let info = resp2.EVENT.EVENTINFO;

      var _city = (resp2.EVENT.EVENTINFO.city).toString;
      var city = "";
      if (_city === "1") {
        city = "La Paz";
      }
      if (_city === "2") {
        city = "Cochabamba";
      }
      if (_city === "3") {
        city = "Santa Cruz";
      }
      if (_city === "4") {
        city = "Tarija";
      }
      if (_city === "5") {
        city = "Sucre";
      }
      if (_city === "6") {
        city = "Potos\u00ED";
      }
      if (_city === "7") {
        city = "Oruro";
      }
      if (_city === "8") {
        city = "Cobija";
      }
      if (_city === "9") {
        city = "Beni";
      }

      Object.assign(eventInfo, info);
      this.setState({
        ...this.state,
        eventListInfo: { products: resp2.PRODUCTS },
        eventid: eventid,
        city: city,
        externalOrderFormData: externalOrderFormData,
        externalOrderFormConfig: externalOrderFormConfig,
        eventimage: resp2.EVENT.IMAGEPATH,
        eventdate: resp2.EVENT.EVENTDATE,
        eventdate2: resp2.EVENT.EVENTDATE2,
        eventmessage: resp2.EVENT.EVENTINFO.message,
        eventname: resp2.EVENT.EVENTINFO.eventname,
        eventInfo: eventInfo,
        userId: resp2.EVENT.CUSTOMERID,
        storeid: resp2.EVENT.STOREID,
      });
      let _pageData = await loadPageV2(
        resp.template,
        this.managepagination,
        this.manageCart,
        this.changeHandler,
        this.manageButton,
        this.toggleModal,
        windowWidth
      );
      this.setState({ template: resp.template });
      this.renderData(_pageData);

      layoutFile = "suggestedCategories_" + getLanguage();

      try {
        let evenInfo = JSON.parse(resp2.EVENT.EVENT_FORMAT);
        layoutFile = evenInfo.pathlayout;
      } catch (EX) {
        console.log("no layout founded");
      }

      resp = await callApi("layaoutManager", "getLayoutTemplateByName", {
        layoutFile: layoutFile,
        foldername: foldername,
      });
	  /*
      var template = resp.template;
      for (let t in template) {
        let config = template[t].config;
        let data = template[t].data;
      }*/
      let _pageData2 = await loadPageV2(
        resp.template,
        this.managepagination,
        this.manageCart,
        this.changeHandler,
        this.manageButton,
        this.toggleModal,
        windowWidth
      );
      this.setState({
        template2: resp.template,
        isLoading: false,
        formstep: 2,
      });
      this.renderData2(_pageData2);
    }
  };
  refreshEventProducts = async (event) => {
    var resp2 = await callApi("frontend", "getCustomerEventById", {
      languageid: getLanguage(),
      eventid: this.state.eventid,
    });
    if (resp2.success) {
      this.setState({
        ...this.state,
        eventListInfo: { products: resp2.PRODUCTS },
      });
    }
  };

  renderData = (_pageData) => {
    try {
      //check_!!! this.setState({city: _pageData[2].data.city.values[0].text})
      var personalData = this.state.eventInfo.CUSTOMERINFO;
      var fieldsPD = Object.keys(personalData[0]);
      for (let r in _pageData) {
        let fieldsF = Object.keys(_pageData[r].data);
        if (fieldsF.toString() === fieldsPD.toString()) {
          if (personalData.length > 0) {
            var arrData = [];
            var item = 1;
            for (let d in personalData) {
              let formName =
                (_pageData[r].config.formsubtitle
                  ? _pageData[r].config.formsubtitle
                  : "ITEM") + item;
              let newDataNames = [];
              let curFields = _pageData[r].data;
              let newData = [];

              for (let i in Object.keys(curFields)) {
                let field = Object.keys(curFields)[i];
                newDataNames.push(field);
                let newValues = [];
                //let initVal;
                if (curFields[field].values) {
                  for (let j in curFields[field].values) {
                    if (j === 0) {
                      //initVal = curFields[field].values[j].value;
                    }
                    let row = {
                      text: "1",
                      value: "value",
                    };
                    newValues.push(row);
                  }
                }
                let objField = {};
                if (curFields[field].values) {
                  objField["values"] = newValues;
                }
                for (let f in Object.keys(curFields[field])) {
                  let fieldName = Object.keys(curFields[field])[f];
                  if (fieldName === "value") {
                    if (Array.isArray(curFields[field].values)) {
                      if (curFields[field].values.length > 0) {
                        objField["value"] = 1;
                      } else {
                        objField["value"] = "";
                      }
                    } else {
                      objField["value"] = "";
                    }
                  } else {
                    objField[fieldName] = curFields[field][fieldName];
                  }
                }
                newData.push(objField);
              }
              let finalData = {};
              for (let i in newDataNames) {
                finalData[newDataNames[i]] = newData[i];
              }
              //let loadData =
              mergeFormData(finalData, personalData[d]);
              arrData.push({
                formName: formName,
                formLabel: formName,
                formConfig: _pageData[r].config,
                formData: finalData,
                formOptionID: item - 1,
              });
              item++;
            }
            _pageData[r]["originaldata"] = _pageData[r].data;
            _pageData[r]["data"] = arrData;
            _pageData[r]["activeForm"] = 0;
          } else {
            //let loadData =
            mergeFormData(_pageData[r].data, personalData[0]);
          }
        } else {
          //let loadData =
          mergeFormData(_pageData[r].data, this.state.eventInfo);
        }
        if (_pageData[r].data["directions"]) {
          let finalValue = _pageData[r].data["directions"].value;
          let finalLabel = "";
          Object.keys(finalValue).map(function (field) {
            finalLabel = finalLabel + finalValue[field] + ", ";
          });
          let values = [{ text: finalLabel, value: finalValue }];
          _pageData[r].data["directions"].values = values;
          _pageData[r].data["directions"].buttonlabel =
            getDefValues().setAddressLabel;
        }
        this.setState({
          ["RENDEROBJ" + _pageData[r].ObjectName]: _pageData[r],
        });
      }
      //console.log("-final-", this.state)
    } catch (err) {
      console.log(">>>>>>>>>>>", err);
    }
  };

  renderData2 = (_pageData) => {
    try {
      //console.log("-_pageData-", _pageData)
      for (let r in _pageData) {
        this.setState({
          ["2RENDEROBJ" + _pageData[r].ObjectName]: _pageData[r],
        });
      }
      //console.log("-final-", this.state)
    } catch (err) {
      console.log(">>>>>>>>>>>", err);
    }
  };

  editForm = (event) => {
    this.setState({ lockform: false, isValid: false });
  };

  toggleModal = (event) => {
    let object = findObjName(event);
    //console.log(object, this.state["RENDEROBJ"+object])
    let newTemplate = toggleModalManager(this.state["RENDEROBJ" + object]);
    this.setState({ ["RENDEROBJ" + object]: newTemplate });
  };

  changeHandler = async (event) => {
    let object = findObjName(event);
    let fieldName = object.split("__");
    if (fieldName[1] === "ADD") {
      this.setState({
        activeMapForm: fieldName[0],
        activeMapValue: fieldName[2],
      });
      this.showpopupdirections();
    } else {
      if (event?.target?.name.indexOf("datos_evento__imagepath") >= 0) {
        //console.log("update image")
        this.setState({ eventimage: event.target.value });
      }

      if (event?.target?.name.indexOf("city") >= 0) {
        var city = "";
        if (event.target.value == "1") {
          city = "La Paz";
        }
        if (event.target.value == "2") {
          city = "Cochabamba";
        }
        if (event.target.value == "3") {
          city = "Santa Cruz";
        }
        if (event.target.value == "4") {
          city = "Tarija";
        }
        if (event.target.value == "5") {
          city = "Sucre";
        }
        if (event.target.value == "6") {
          city = "Potos\u00ED";
        }
        if (event.target.value == "7") {
          city = "Oruro";
        }
        if (event.target.value == "8") {
          city = "Cobija";
        }
        if (event.target.value == "9") {
          city = "Beni";
        }

        this.setState({ city: city });
      }
      let newTemplate = changeHandlerManager(
        this.state["RENDEROBJ" + fieldName[0]],
        fieldName[1],
        event.target.value
      );
      this.setState({ ["RENDEROBJ" + fieldName[0]]: newTemplate });
    }
  };

  handleChangeSKU = (event) => {
    this.setState({ skuToAdd: event.target.value });
  };

  delSku = async (sku) => {
    let newSkus = this.state.skus;
    let pos = find(this.state.skus, sku, "productsku");
    newSkus.splice(pos, 1);
    this.setState({ skus: newSkus });
  };

  addSKUToOrder = async (event) => {
    let newSkus = this.state.skus;
    var pos1 = find(
      this.state.eventListInfo.products,
      this.state.skuToAdd,
      "productsku"
    );
    let pos2 = find(newSkus, this.state.skuToAdd, "productsku");

    console.log(this.state.eventListInfo.products);

    var isNew = false;
    var isValid = true;
    if (pos1 < 0) {
      //console.log("pos1")
      this.setState({ isLoading2: true });
      var formData = {
        sku: this.state.skuToAdd,
        productid: 0,
        eventid: this.state.eventid,
        quantity: -1,
      };
      var resp = await callApi("frontend", "setProductEvent", formData);
      console.log("resp", resp);
      if (resp.success) {
        isNew = true;
        showMessage(toast, getDefValues().successfullyAddedToEventListLabel);
        await this.refreshEventProducts();
        pos1 = find(
          this.state.eventListInfo.products,
          this.state.skuToAdd,
          "productsku"
        );
      } else {
        isValid = false;
        showError(toast, getDefValues().errorAddedToNewSKULabel);
      }
      this.setState({ isLoading2: false });
    }

    if (isValid) {
      //console.log( this.state.eventListInfo.products)
      let skuData = this.state.eventListInfo.products[pos1];
      if (pos2 < 0) {
        //console.log("1")

        let maxQty = skuData.quantity - skuData.quantity_assigned;
        if (maxQty > 0) {
          let newSku = {
            productid: skuData.productid,
            productsku: skuData.productsku,
            image: skuData.image,
            quantity: 1,
            maxQuantity: skuData.maxQuantity,
            quantity_assigned: skuData.quantity_assigned,
          };
          newSkus.push(newSku);
        } else {
          if (!isNew) {
            showWarning(toast, getDefValues().skuExceededMessage2);
          }
          let newSku = {
            productid: skuData.productid,
            productsku: skuData.productsku,
            image: skuData.image,
            quantity: 1,
            maxQuantity: skuData.maxQuantity,
            quantity_assigned: skuData.quantity_assigned,
          };
          newSkus.push(newSku);
        }
      } else {
        console.log("2", newSkus[pos2]["quantity"], skuData.maxQuantity);

        if (newSkus[pos2]["quantity"] < skuData.maxQuantity) {
          let currSkuData = newSkus[pos2];
          let currQty = currSkuData.quantity + 1;
          let maxQty = skuData.quantity - skuData.quantity_assigned;

          if (currQty <= maxQty) {
            newSkus[pos2]["quantity"] = currQty;
          } else {
            newSkus[pos2]["quantity"] = currQty;
            if (!isNew) {
              showWarning(toast, getDefValues().skuExceededMessage2);
            }
          }
        } else {
          showError(toast, getDefValues().skuExceededMessage);
        }
      }
      this.setState({ skus: newSkus });
    }
  };

  changeHandlerD = async (event) => {
    //console.log("eventD", event)
    var formData = changeHandler(event, this.state.externalOrderFormData);
    this.setState({ externalOrderFormData: formData });

    if (event.key === "Enter") {
      this.registerOrder(event);
    }
  };

  registerOrder = async (event) => {
    this.setState({ isLoading2: true });
    try {
      var formData = {
        skus: this.state.skus,
        eventid: this.state.eventid,
        orderinfo: this.state.eventInfo,
      };
      var resp = await validateForm(this.state.externalOrderFormData, toast);

      if (resp.isValid) {
        let _formData = this.state.externalOrderFormData;
        _formData = parseFormData(_formData);

        Object.assign(formData, _formData);

        formData["storeid"] = this.state.storeid;
        //console.log(formData)
        let resp = await callApi("frontend", "setExternalOrders", formData);
        if (resp.success) {
          showMessage(toast, getDefValues().successfullyOrderRegisteredLabel);
          await this.refreshEventProducts();
          this.setState({
            ...this.state,
            modal3: false,
            skuToAdd: "",
            externalOrder: true,
            externalOrderId: resp.orderid,
            skus: [],
          });
        } else {
          showError(toast, getDefValues().errorOrderRegisteredLabel);
        }
      }
    } catch (Excep) {
      this.setState({ isLoading2: false });
      console.log(">>>>>>>", Excep);
    } finally {
      this.setState({ isLoading2: false });
    }
  };

  changeForm = async (event) => {
    this.setState({ isLoading: true });
    let object = findObjName(event);
    let fieldName = object.split("__");
    let newTemplate = this.state["RENDEROBJ" + fieldName[0]];
    newTemplate["activeForm"] = event.target.value;
    this.setState({ ["RENDEROBJ" + fieldName[0]]: newTemplate });
    setTimeout(
      function () {
        this.setState({ ["RENDEROBJ" + fieldName[0]]: newTemplate });
        this.setState({ isLoading: false });
      }.bind(this),
      250
    );
  };

  manageCart = async (event) => {
    //let object = findObjName(event);
    let container;
    if (event.target.name) {
      container = event.target.id;
    } else {
      container = event.target.parentNode.id;
    }

    try {
      this.setState({ isLoading2: true });
      var resp = await manageCart(
        event,
        this.state["RENDEROBJ" + container].data,
        this.manageCart,
        toast
      );
      if (resp.success) {
        if (!resp.redirect) {
          this.setState({
            ...this.state,
            isLoading: false,
            //cardData: resp._cardData
          });
          if (resp.dataCart) {
            this.props.updateCart(resp.dataCart);
          }
        } else {
          this.setState({
            ...this.state,
            isLoading: false,
            selectedProductID: resp.selectedProductID,
            selectedPath: resp.selectedPath,
            selectedCategory: resp.selectedCategory,
            redirectProductDetails: true,
          });
        }
      } else {
        this.setState({ ...this.state, isLoading: false });
        console.log(">>>>>>>", resp.message);
      }

      this.setState({ isLoading2: false });
    } catch (Excep) {
      this.setState({ isLoading2: false });
      console.log(">>>>>>>", Excep);
    }
  };

  showpopupdirections = async (event) => {
    this.setState({ isLoading2: true });
    let form = await callApi("layaoutManager", "getLayoutTemplateByName", {
      layoutFile: "addressForm_" + getLanguage(),
      foldername: foldername + "private/",
    });
    let rendegObj = await getRenderObj(
      form.template[0],
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    rendegObj.curdata.config["btncolor"] = "secondary";
    delete rendegObj.curdata.data["alias"];

    rendegObj.curdata.data["city"].values = [
      { value: this.state.city, text: this.state.city },
    ];
    rendegObj.curdata.data["city"].value = this.state.city;

    this.setState({
      addressFormData: rendegObj.curdata.data,
      addressFormConfig: rendegObj.curdata.config,
      shippingFormId: form.template[0].componentid,
    });
    this.setState({
      modal4: true,
      showMap: false,
      showFormAdd: true,
      isLoading2: false,
    });
  };
  changeHandlerA = async (event) => {
    //console.log("eventA", event)
    var formData = changeHandler(event, this.state.addressFormData);
    this.setState({ addressFormData: formData });
    if (event.key === "Enter") {
      this.saveTempLocation(event);
    }
  };
  closeMap = (event) => {
    this.setState({ ...this.state, modal4: false, showMap: false });
    this.props.toggleListener();
  };

  hideMap = (event) => {
    this.setState({ showMap: false, showFormAdd: true });
    this.props.toggleListener();
  };

  saveTempLocation = async (event) => {
    var resp = await validateForm(this.state.addressFormData, toast);
    if (resp.isValid) {
      this.props.toggleListener();
      this.setState({
        showMap: true,
        showFormAdd: false,
        address: this.state.addressFormData.address.value,
        city: this.state.addressFormData.city.value,
      });
    }
  };

  setMyLocation = async (event) => {
    this.setState({ showMap: false });
    await navigator.geolocation.getCurrentPosition(
      async function (position) {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
        this.setState({
          latitude: latitude,
          longitude: longitude,
          showMap: true,
        });
      }.bind(this)
    );
  };

  setLocation = async (event, add) => {
    if (event.lat) {
      this.setState({
        clatitude: event.lat,
        clongitude: event.lng,
        caddress: add,
      });
    }
  };

  confirmLocation = async (event) => {
    this.setState({ isLoading2: true });
    try {
      let _formData = this.state.addressFormData;
      _formData = parseFormData(_formData);
      var finalValue = {};
      var finalLabel = "";
      Object.keys(_formData).map(function (field) {
        finalLabel = finalLabel + _formData[field] + ", ";
        finalValue[field] = _formData[field];
      });
      finalLabel = finalLabel + " LAT:" + event.lat + ", LNG:" + event.lng;

      let title = (_formData["alias"] || 'Evento').toUpperCase();
      var addLabel = _formData["address"];

      finalValue["latitude"] = event.lat;
      finalValue["longitude"] = event.lng;
      finalValue["zonecode"] = event.zonecode;
      var formData = this.state["RENDEROBJ" + this.state.activeMapForm]["data"];
      //formData[this.state.activeMapValue].values = [{value: finalValue, text:"",aditionalText :finalLabel}]
      formData[this.state.activeMapValue].values = [
        {
          value: finalValue,
          text: title /*---*/,
          aditionalText: addLabel /*finalLabel*/,
        },
      ];
      formData[this.state.activeMapValue].value =
        formData[this.state.activeMapValue].values[0].value;

      this.setState({
        modal4: false,
        showMap: false,
        showFormAdd: true,
        haveDirection: true,
      });
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
    } finally {
      this.setState({ isLoading2: false });
    }
  };

  manageButton = async (event) => {
    try {
      this.setState({ isLoading2: true });
      let object = findObjName(event);
      var fieldName = object.split("__");
      //let storesInfo = getSyncStoresInfo()
      var formData = {
        userId: this.state.userId,
        storeid: this.state.storeid,
        eventid: this.state.eventid,
      };
      var resp = await manageButton(
        fieldName[1],
        this.state,
        toast,
        formData,
        getDefValues().eventUpdatedLabel
      );

      if (resp.success) {
        //console.log(formData)

        let eventname = formatPathName(formData.eventname);
        let eventToken = eventname.replace(/[^a-zA-Z0-9]/g, "-");
        eventToken = eventToken.replaceAll("---", "-");
        eventToken = eventToken.replaceAll("--", "-");
        eventToken += "-" + this.state.eventid;

        var shareUrl =
          process.env.REACT_APP_URL_FRONT_CONFIG +
          "/customer/event/event-details/" +
          eventToken;

        var eventdate2 = this.state.eventdate2;
        if (typeof formData.eventdate != "string") {
          //console.log("fecha")

          const today = new Date(formData.eventdate);
          const yyyy = today.getFullYear();
          let mm = today.getMonth() + 1; // Months start at 0!
          let dd = today.getDate();

          if (dd < 10) dd = "0" + dd;
          if (mm < 10) mm = "0" + mm;

          eventdate2 = dd + "/" + mm + "/" + yyyy;
        }

        //console.log(this.state.eventdate2, "new:", eventdate2)

        this.setState({
          lockform: true,
          eventimage: formData.imagepath,
          eventdate2: eventdate2,
          eventname: formData.eventname,
          eventperson: formData.formdata__datos_personales[0].firstname,
          eventmessage: formData.message,
          shareUrl: shareUrl,
          formstep: 2,
        });
      }

      this.setState({ isLoading2: false });
    } catch (err) {
      console.log(">>>>>>>>>>>", err);
      this.setState({ isLoading2: false });
    }
  };

  managepagination = (event) => {
    this.setState({ isLoading: true });

    //let object = findObjName(event);
    let _container;
    if (event.target.name) {
      _container = event.target.id.split("__");
    } else {
      _container = event.target.parentNode.id.split("__");
    }
    let container = _container[0];
    setTimeout(
      function () {
        var newpagesettings = managepagination(
          this.state["RENDEROBJ" + container].config.pagesettings,
          event
        );
        var newcardConfig = {
          pagewidth: "col-md-12 col-lg-12 col-xl-12",
          colsize: "col-6",
          colsizelg: "col-lg-2_5",
          colsizemd: "col-md-4",
          colsizesm: "col-sm-6",
          pagination: true,
          managepagination: this.managepagination,
          pagesettings: newpagesettings,
          /*overtop            :"YES"*/
        };
        this.setState({ cardConfig: newcardConfig, isLoading: false });
      }.bind(this),
      1000
    );
  };
  managesort = (event) => {
    this.setState({ isLoading: true });
    //console.log(event.target)
    //let object = findObjName(event);
    let container;
    if (event.target.name) {
      container = event.target.id;
    } else {
      container = event.target.parentNode.id;
    }
    setTimeout(
      function () {
        var newcardData = managesort(
          this.state["RENDEROBJ" + container],
          event
        );
        this.setState({ cardData: newcardData, isLoading: false });
      }.bind(this),
      1000
    );
  };

  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;

    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });

    if (nr === 4 && !this.state.modalNumber) {
      this.setState({ showMap: false });
    }
  };

  toggleDelete = () => {
    this.setState({
      showConfirm: !this.state.showConfirm,
    });
  };

  confimDelete = async (event) => {
    this.setState({ isLoading: true });
    var data = await updateEventList(
      this.state.eventListInfo,
      this.state.listProductId,
      this.state.listAction,
      toast,
      this.state.listValue
    );
    if (data) {
      this.setState({ ...this.state, isLoading: false, eventListInfo: data });
      let qty = 0;
      data.products.map(function (item) {
        qty = qty + item.quantity;
      });
      this.props.setQtyEvent(qty);
    } else {
      this.setState({ ...this.state, isLoading: false, showConfirm: false });
    }
  };
  updateEventList2 = async (event) => {
    let object = findObjName(event);
    var values = object.split("__");
    const skus = this.state.skus;
    let pos = find(skus, values[1], "productsku");
    if (values[0] === "increase") {
      console.log("add", skus[pos]);
      if (skus[pos]["quantity"] < skus[pos]["maxQuantity"]) {
        skus[pos]["quantity"] = skus[pos]["quantity"] + 1;
      } else {
        showError(toast, getDefValues().skuExceededMessage);
      }
    } else {
      if (skus[pos]["quantity"] > 1) {
        skus[pos]["quantity"] = skus[pos]["quantity"] - 1;
      }
    }
    this.setState({ ...this.state, skus: skus });
    console.log("entr", event.target, values, this.state.skus);
  };

  updateEventList = async (event) => {
    //console.log("entr",event	)
    this.setState({ isLoading: true });

    try {
      let object = findObjName(event);
      var values = object.split("__");
      var action = values[0];
      var productId = values[1];

      //var eventListInfo = this.state.eventListInfo
      if (action === "del") {
        this.setState({
          ...this.state,
          isLoading: false,
          listAction: action,
          listProductId: productId,
          listValue: event.target.value,
          showConfirm: true,
        });
      } else {
        var data = await updateEventList(
          this.state.eventListInfo,
          productId,
          action,
          toast,
          event.target.value
        );
        if (data) {
          this.setState({
            ...this.state,
            isLoading: false,
            eventListInfo: data,
          });
          let qty = 0;
          data.products.map(function (item) {
            qty = qty + item.quantity;
          });
          this.props.setQtyEvent(qty);
        } else {
          this.setState({ ...this.state, isLoading: false });
        }
      }
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };

  getTokenEventlist = async () => {
    try {
      if (this.state.shareUrl !== "") {
        this.setState({ ...this.state, modal2: true });
      } else {
        this.setState({ ...this.state, isLoading2: true, modal2: true });

        var formData = {
          eventid: this.state.eventid,
          eventdate: this.state.eventdate,
          eventmessage: this.state.eventmessage,
          eventname: this.state.eventname,
          eventimage: this.state.eventimage,
          storeid: this.state.storeid,
        };
        let eventname = formatPathName(this.state.eventname);
        let eventToken = eventname.replace(/[^a-zA-Z0-9]/g, "-");
        eventToken = eventToken.replaceAll("---", "-");
        eventToken = eventToken.replaceAll("--", "-");
        eventToken += "-" + this.state.eventid;
        //console.log(eventToken)
        var resp = await callApi("frontend", "getTokenEventlist", formData);
        if (resp.success) {
          var url =
            process.env.REACT_APP_URL_FRONT_CONFIG +
            "/customer/event/event-details/" +
            eventToken;
          //url = "http://cidev-front.casaideas.com.bo"+"/customer/event/event-details/"+resp.token
          try {
            const bitly = new BitlyClient(process.env.REACT_APP_BITLYKEY, {});

            resp = await bitly.shorten(url);
            url = resp.url;
          } catch (Exc) {
            //console.log("Exc bitly >>>",Exc )
          }
          var message = (
            <span className="text-secondary">
              {" "}
              {getDefValues().myWishListTokenMessage}{" "}
            </span>
          );
          this.setState({ ...this.state, shareUrl: url, message });
        } else {
          showError(toast, getDefValues().errorGenerationEventListsMessage);
        }
      }
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
    } finally {
      this.setState({ isLoading2: false });
    }
  };
  toggleMoreUrl = () => {
    this.setState({
      showMoreUrl: !this.state.showMoreUrl,
    });
  };

  copyTextToClipboard = async () => {
    await navigator.clipboard.writeText(this.state.shareUrl);
    this.setState({
      isCopied: true,
    });
  };

  shareEvent = (event) => {
    try {
      var socialNetwork = findObjName(event);

      //	console.log(socialNetwork)

      var url = this.state.shareUrl;
      var title = this.state.eventname;

      shareEvent(
        socialNetwork,
        url,
        title,
        500,
        350,
        getDefValues().shareEventMessageLabel
      );
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
    }
  };

  callBackSN = async (res) => {
    try {
      this.setState({ isLoading: true });
      var status = await loginSN(res, toast, this.state.rememberme);
      if (status) {
        window.location.reload();
        //this.props.setLoginInfo(res)
        this.setState({ reloadPage: true });
      }
      this.setState({ isLoading: false });
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };

  callBackSNError = (res) => {
    console.log("Login failed: res:", res);
  };

  changeHandlerL = async (event) => {
    //let resp = await validateForm(this.state.formData, toast,event);
    //this.setState({formData:resp.formData});

    //console.log("eventL", event)

    var formData = changeHandler(event, this.state.formData);
    this.setState({ formData: formData });

    if (event.key === "Enter") {
      this.login(event);
    }
  };

  login = async (event) => {
    try {
      this.setState({ isLoading: true });
      var resp = await login(this.state.formData, this.state.rememberme, toast);
      if (resp.success) {
        this.setState({ isLoading: false, modal1: false, reloadPage: true });
        this.props.setLoginInfo(event);
        window.location.reload();
      } else {
        this.setState({ isLoading: false });
      }
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };

  toggleLogin = (nr) => () => {
    let show = "show" + nr;
    this.setState({
      [show]: !this.state[show],
    });
  };

  changePanel = (nr) => () => {
    this.setState({
      formstep: nr,
    });
  };

  render() {
    const MapLoader = withScriptjs(Map);

    if (this.state.showEvents) {
      return (
        <div style={{ height: 420 }}>
          <MDBModal
            isOpen={this.state.showEvents}
            fade
            centered
            className="fullheigth-modal show-events-modal"
          >
            <MDBModalBody>
              <p className="flex items-left mt-3">
                <span className="h4-responsive">
                  {" "}
                  {getDefValues().selectEventLabel}{" "}
                </span>
              </p>

              <div className="row mt-50 mb-10 body-content">
                <div className="col-12">
                  {this.state.events.map((item) => (
                    <div className="row flex middle ">
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2  ">
                        <img
                          onClick={() => this.setEventById(item.EVENTID)}
                          alt={item.eventname}
                          src={getImagePath(item.IMAGEPATH)}
                          className="card-img-top"
                        />
                      </div>
                      <div className="col-9 col-sm-9 col-md-9 col-lg-10  pl-1">
                        <span onClick={() => this.setEventById(item.EVENTID)}>
                          {item.EVENTINFO.eventname}{" "}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </MDBModalBody>
          </MDBModal>
        </div>
      );
    } else {
      /*<NotFounded messages={this.state.messages}/>*/
      if (this.state.noEvents) {
        return <Redirect to={{ pathname: "/customer/event/create-event" }} />;
      } else {
        return this.props.showSearcher ? (
          ""
        ) : (
          <>
            <PageLoading isLoading={this.state.isLoading} />
            <PageLoading isLoading={this.state.isLoading2} />

            <Modal
              content={
                <>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center mb-30 mt-30 ">
                      <h4> {getDefValues().deleteProductEventMessage} </h4>
                    </div>
                  </div>
                </>
              }
              staticBackdrop={false}
              toogleCloseButton={true}
              formAction={this.confimDelete}
              btncolor={"secondary"}
              formButtonLabel={getDefValues().yeslabel}
              secondaryFormButtonLabel={getDefValues().nolabel}
              toggleModal={this.toggleDelete}
              modal={this.state.showConfirm}
              btncolorbtncolor="secondary"
            />

            <Modal
              height={150}
              minHeight={150}
              lg={false}
              overflowY={"hidden"}
              top={false}
              content={
                <div
                  style={{ height: 140 }}
                  className="row flex middle text-center"
                >
                  <div className="col-12">
                    <h3> Orden Generada Nro: {this.state.externalOrderId}</h3>
                  </div>
                </div>
              }
              staticBackdrop={true}
              toogleCloseButton={true}
              hideCloseButton={true}
              toggleModal={this.showExternalOrder}
              modal={this.state.externalOrder}
            />

            <MDBModal
              isOpen={this.state.modal4}
              toggle={this.toggle(4)}
              fade
              centered
              className="fullheigth-modal"
            >
              <MDBModalHeader>
                <span className="h6-responsive">
                  {" "}
                  {getDefValues().chooseMapPoint}{" "}
                </span>
              </MDBModalHeader>
              <MDBModalBody className="popup-map">
                {this.state.showFormAdd ? (
                  <Form
                    formData={this.state.addressFormData}
                    changeHandler={this.changeHandlerA}
                    formConfig={this.state.addressFormConfig}
                  />
                ) : (
                  ""
                )}

                {this.state.showMap ? (
                  <MapLoader
                    coords={{
                      lat: this.state.latitude,
                      lng: this.state.longitude,
                    }}
                    height="380px"
                    setMyLocation={this.setMyLocation}
                    setLocation={this.setLocation}
                    address={this.state.address}
                    city={this.state.city}
                    googleMapURL={
                      "https://maps.googleapis.com/maps/api/js?key=" +
                      GoogleMapsAPI +
                      "&libraries=places"
                    }
                    backLocation={this.hideMap}
                    cancelLocation={this.closeMap}
                    btncolor="secondary"
                    confirmLocation={this.confirmLocation}
                    loadingElement={<div style={{ height: `380px` }}></div>}
                  />
                ) : (
                  ""
                )}
              </MDBModalBody>

              <div className="card">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 flex items-center">
                    {this.state.showFormAdd ? (
                      <>
                        <button
                          className={
                            "btn btn-md btn-secondary shipping-form-btn mr-1"
                          }
                          type="button"
                          onClick={this.saveTempLocation}
                        >
                          {getDefValues().selectLocationLabel}
                        </button>
                        <button
                          className="btn btn-md btn-outline-secondary shipping-form-btn"
                          type="button"
                          onClick={this.closeMap}
                        >
                          {getDefValues().cancelLabel}
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </MDBModal>

            <MDBModal
              isOpen={this.state.modal1}
              toggle={this.toggle(1)}
              fade
              centered
              className="fullheigth-modal"
            >
              <MDBModalBody>
                <p className="flex items-left mt-3">
                  <img src={ico_login} alt="login" />
                  <span className="h3-responsive">
                    {" "}
                    {getDefValues().loginLabel}{" "}
                  </span>
                </p>

                {this.state.needLogin ? (
                  <MDBAlert color="warning" className="">
                    <p sytle={{ marginBottom: 5 }}>
                      <span className="h5-responsive">
                        {" "}
                        {getDefValues().needLoginMessage}{" "}
                      </span>
                    </p>
                  </MDBAlert>
                ) : (
                  ""
                )}

                <div className="text-muted flex items-center mt-3">
                  <span>{getDefValues().loginWithSocialNetwork}</span>
                </div>

                <p className="mobile-col col-12 social-login text-center">
                  <GoogleLogin
                    clientId={process.env.REACT_APP_API_KEY_GOOGLE}
                    buttonText="Google"
                    className="google"
                    onSuccess={this.callBackSN}
                    onFailure={this.callBackSNError}
                    cookiePolicy={"single_host_origin"}
                  />
                  <FacebookLogin
                    cssClass="ml-2 facebook"
                    appId={process.env.REACT_APP_API_KEY_LOGIN_FB}
                    autoLoad={false}
                    fields="name,email,picture,last_name,first_name"
                    scope="public_profile, email"
                    callback={this.callBackSN}
                    onFailure={this.callBackSNError}
                    textButton="Facebook"
                    icon="fa-facebook"
                  />
                </p>
                <div className="login-or mt-30">
                  <span
                    style={{
                      backgroundColor: "#e5e5e5",
                      height: 1,
                      marginBottom: 5,
                      marginTop: 0,
                      display: "block",
                    }}
                  ></span>

                  <span className="span-or">{getDefValues().orLabel}</span>
                </div>

                <Login
                  formData={this.state.formData}
                  toggleF={this.toggleLogin("forgot")}
                  toggleL={this.toggleLogin("login")}
                  toggleR={this.toggleLogin("recovery")}
                  changeHandler={this.changeHandlerL}
                  needLogin={this.state.needLogin}
                  login={this.login}
                  guest={this.guest}
                />
              </MDBModalBody>
            </MDBModal>

            <MDBModal
              isOpen={this.state.modal3}
              toggle={this.toggle(3)}
              fade
              centered
              className="f-modal"
            >
              <MDBModalBody style={{ overflowY: "scroll", maxHeight: 640 }}>
                <p className="flex items-left mt-3">
                  <span className="h3-responsive">
                    {" "}
                    {getDefValues().addSKUstoEventLabel}{" "}
                  </span>
                </p>
                <span
                  style={{
                    backgroundColor: "#e5e5e5",
                    height: 1,
                    marginBottom: 5,
                    marginTop: 0,
                    display: "block",
                  }}
                ></span>

                <div className="row mt-50 mb-30">
                  <div className="col-12 flex items-right">
                    <input
                      className="pagination-search-input"
                      value={this.state.skuToAdd}
                      onChange={this.handleChangeSKU}
                      onKeyDown={this.handleChangeSKU}
                      type="text"
                      name="skuToAdd"
                      placeholder={getDefValues().searchProductLabel}
                    />
                    <button
                      className={"btn btn-secondary btn-sm ml-1"}
                      type="button"
                      style={{ width: "25%" }}
                      onClick={this.addSKUToOrder}
                    >
                      {getDefValues().shortbtnaddbutton}
                    </button>
                  </div>
                </div>
                {this.state.skus.length > 0 ? (
                  <>
                    <div className="row mt-30 row text-left">
                      <div className="col-2"></div>
                      <div className="col-6">SKU</div>
                      <div className="col-2">
                        {getDefValues().shopCartQuantityLabel.toUpperCase()}
                      </div>
                      <div className="col-2"></div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {this.state.skus.map((item) => (
                  <div className="row flex middle items-left">
                    <div className="col-2">
                      <img
                        alt={item.productname}
                        src={getImagePath(item.image)}
                        className="card-img-top"
                      />
                    </div>
                    <div className="col-6">
                      <b>{item.productsku}</b>
                    </div>
                    <div className="col-2">
                      <div className="flex col-text-quantity">
                        <div className="detail-qty-shopcart">
                          <button
                            name={"decrease__" + item.productsku}
                            onClick={this.updateEventList2}
                            className="qty-down"
                          >
                            <i className="fas fa-minus"></i>
                          </button>
                          <input
                            type="text"
                            className="qty-val"
                            name={"quantity__" + item.productsku}
                            value={
                              item.prequantity != null
                                ? item.prequantity
                                : item.quantity
                            }
                          />
                          <button
                            name={"increase__" + item.productsku}
                            onClick={this.updateEventList2}
                            className="qty-up"
                          >
                            <i className="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-2">
                      <a
                        name={item.productsku}
                        onClick={() => this.delSku(item.productsku)}
                        className="text-muted"
                      >
                        <img
                          className="icon-trash"
                          src={getImagePath("/catalog/images/ico_trash.svg")}
                          alt="delete-product"
                          width="20px"
                          height="20px"
                        />
                      </a>
                    </div>
                  </div>
                ))}

                {this.state.skus.length > 0 ? (
                  <>
                    <Form
                      formData={this.state.externalOrderFormData}
                      formConfig={this.state.externalOrderFormConfig}
                      changeHandler={this.changeHandlerD}
                    />
                    <div className="row mt-3 mb-3">
                      <div className="col-12 text-center">
                        <button
                          className={"btn btn-secondary btn-sm"}
                          type="button"
                          onClick={this.registerOrder}
                        >
                          {getDefValues().setOrderLabel}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </MDBModalBody>
            </MDBModal>

            <div className="page-wrapper" onClick={this.props.hideCart}>
              {this.state.redirectProductDetails ? (
                <Redirect
                  to={{
                    pathname:
                      (this.state.selectedCategory
                        ? "/" + this.state.selectedCategory + "/"
                        : "/productDetails/") +
                      this.state.selectedProductID +
                      "/" +
                      (this.state.selectedPath
                        ? this.state.selectedPath
                        : "show"),
                  }}
                />
              ) : (
                ""
              )}

              <div className="content-2 container-page container-fluid custom-container">
                <div className="row flex items-center">
                  <div className="col-md-12 col-lg-9 col-lx-7">
                    <div className="row mt-30">
                      <div className="col-12 text-right">
                        <a
                          href="/customer/event/create-event"
                          className="btn btn-sm btn-secondary"
                        >
                          {getDefValues().createNewEventLabel.toUpperCase()}
                        </a>
                      </div>
                    </div>

                    {this.state.moreEvents && (
                      <div className="row mt-2 mb-20">
                        <div className="col-12 text-right">
                          <button
                            className={"btn btn-sm btn-secondary"}
                            type="button"
                            onClick={() => {
                              this.setState({ showEvents: true });
                            }}
                          >
                            {" " +
                              getDefValues().showOtherEvents.toUpperCase() +
                              " "}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row flex items-center">
                  <div className="col-md-12 col-lg-9 col-lx-7">
                    <EventMessage
                      modal={this.state.modal2}
                      toggle={this.toggle(2)}
                      toggleI={this.changePanel(1)}
                      toggleL={this.changePanel(2)}
                      eventimage={this.state.eventimage}
                      eventdate={this.state.eventdate2}
                      eventname={this.state.eventname}
                      eventperson={this.state.eventperson}
                      eventmessage={this.state.eventmessage}
                      setEventMode={this.props.setEventMode}
                      shareUrl={this.state.shareUrl}
                      copyTextToClipboard={this.copyTextToClipboard}
                      isCopied={this.state.isCopied}
                      toggleMoreUrl={this.toggleMoreUrl}
                      showMoreUrl={this.state.showMoreUrl}
                      shareEvent={this.shareEvent}
                    />

                    <div className="row mt-30">
                      <div className="col-12">
                        <div className="steps-form-2">
                          <div
                            style={{
                              width: "100%",
                              marginLeft: 0,
                              display: "flex",
                            }}
                            className="steps-row"
                          >
                            <div
                              className="steps-step"
                              style={{ display: "inline-block", width: "50%" }}
                            >
                              <MDBBtn
                                onClick={this.changePanel(1)}
                                className={
                                  "btn btn-" +
                                  (this.state.formstep === 1
                                    ? "primary"
                                    : "light") +
                                  " cbtn-circle"
                                }
                              >
                                <div
                                  className={
                                    "cbtn-circle-" +
                                    (this.state.formstep === 1
                                      ? "active"
                                      : "inactive")
                                  }
                                ></div>
                              </MDBBtn>
                              <p>
                                {getDefValues().myEventFormLabel.toUpperCase()}
                              </p>
                            </div>
                            <div
                              className="steps-step"
                              style={{ display: "inline-block", width: "50%" }}
                            >
                              <MDBBtn
                                onClick={this.changePanel(2)}
                                className={
                                  "btn btn-" +
                                  (this.state.formstep === 2
                                    ? "primary"
                                    : "light") +
                                  " cbtn-circle"
                                }
                              >
                                <div
                                  className={
                                    "cbtn-circle-" +
                                    (this.state.formstep === 2
                                      ? "active"
                                      : "inactive")
                                  }
                                ></div>
                              </MDBBtn>
                              <p>
                                {getDefValues().eventShopTitle.toUpperCase()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.formstep === 1 ? (
                      <div className="card ">
                        {Object.keys(this.state).map((obj) => (
                          <>
                            {obj.startsWith("RENDEROBJ") &&
                            !this.state.modal2 &&
                            !this.state.isLoading ? (
                              <>
                                {this.state[obj].type === "FORM" ? (
                                  <Form
                                    name={this.state[obj].ObjectName}
                                    container={this.state[obj].ObjectName}
                                    formData={this.state[obj].data}
                                    activeForm={this.state[obj].activeForm}
                                    changeForm={this.changeForm}
                                    changeHandler={this.changeHandler}
                                    manageButton={this.manageButton}
                                    formConfig={this.state[obj].config}
                                    lockform={this.state.lockform}
                                    editForm={this.editForm}
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.formstep === 2 ? (
                      <>
                        <EventProductsInfo
                          toggle={this.toggle(3)}
                          isLoading={this.state.isLoading}
                          eventInfo={this.state.eventInfo}
                          resumeStyle={this.state.resumeStyle}
                          products={
                            !this.state.modal2
                              ? this.state.eventListInfo.products
                              : []
                          }
                          updateEventList={this.updateEventList}
                          getTokenEventlist={this.getTokenEventlist}
                          addSkus={this.addSkus}
                          eventid={this.state.eventid}
                          token={this.state.token}
                          refreshEventProducts={this.refreshEventProducts}
                        />
                        {/*
											<h6 className="mt-30"> 
												{getDefValues().addEventProductsMessage}
											</h6>
											*/}
                        <div className="mt-20">
                          {!this.state.isLoading &&
                            Object.keys(this.state).map((obj) => (
                              <>
                                {obj.startsWith("2RENDEROBJ") &&
                                !this.state.modal2 &&
                                !this.state.isLoading ? (
                                  <>
                                    {this.state[obj].type === "TEXT" ? (
                                      <Text
                                        name={this.state[obj].ObjectName}
                                        textData={this.state[obj].data}
                                        textConfig={this.state[obj].config}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {this.state[obj].type === "IMAGE" ? (
                                      <Image
                                        name={this.state[obj].ObjectName}
                                        imageData={this.state[obj].data}
                                        imageConfig={this.state[obj].config}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {this.state[obj].type === "VIDEO" ? (
                                      <Video
                                        name={this.state[obj].ObjectName}
                                        videoData={this.state[obj].data}
                                        videoConfig={this.state[obj].config}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {this.state[obj].type ===
                                    "CARDCONTAINER" ? (
                                      <Card
                                        name={this.state[obj].ObjectName}
                                        cardData={this.state[obj].data}
                                        cardConfig={this.state[obj].config}
                                      />
                                    ) : (
                                      ""
                                    )}

                                    {this.state[obj].type === "CARACOL" ? (
                                        <Caracol
                                          name={this.state[obj].ObjectName}
                                          caracolData={this.state[obj].data}
                                          caracolConfig={this.state[obj].config}
                                        />
                                      ) : (
                                      ""
                                    )} 
                                    {this.state[obj].type === "SLIDERIMAGE" ? (
                                      <SliderImage
                                        sliderData={this.state[obj].data}
                                        sliderConfig={this.state[obj].config}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {this.state[obj].type === "SLIDER" ||
                                    this.state[obj].type ===
                                      "SLIDERCONTAINER" ? (
                                      <Slider
                                        name={this.state[obj].ObjectName}
                                        sliderData={this.state[obj].data}
                                        sliderConfig={this.state[obj].config}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {this.state[obj].type === "BUTTON" ? (
                                      <Button
                                        name={this.state[obj].ObjectName}
                                        buttonData={this.state[obj].data}
                                        buttonConfig={this.state[obj].config}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ))}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  }
}
export default page;
