/* eslint-disable array-callback-return */
/*****components***/
import React, { Component } from "react";

import { Redirect } from "react-router-dom";
/*custom components*/
import PageLoading from "../../components/PageLoading";

import Modal from "../../components/Modal";
import Form from "../../components/Form";
import Caracol from "../../components/Caracol";
import Card from "../../components/Card";
import List from "../../components/List";
import BlogContainer from "../../components/BlogContainer";
import Slider from "../../components/Slider";
import SliderImage from "../../components/SliderImage";

import Text from "../../components/Text";
import Button from "../../components/Button";
import Image from "../../components/Image";
import Video from "../../components/Video";
import CategoryBlog from "../../components/CategoryBlog";
import EventMessage from "../../components/EventMessage";
import Map from "../../components/Map";
import Login from "../../components/Login";
import NotFounded from "../../components/NotFounded";
import {
  toast,
  MDBAlert,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import ico_login from "../../assets/images/ico_login.svg";
//import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from "react-google-login";
/*functions*/
import {
  manageCart,
  getShopCart,
  manageButton,
  managepagination,
  loginSN,
  findValueById,
  getSyncStoresInfo,
  getCookie,
  getDefValues,
  login,
  getRenderObj,
  getLanguage,
  managesort,
  findObjName,
  toFixed,
  setGTMEvent,
  find,
  getLoginInfo,
  changeHandler,
  changeHandlerManager,
  toggleModalManager,
  loadPageV2,
  mergeFormData,
  callApi,
  validateForm,
  parseFormData,
} from "../../common/functions";
import { withScriptjs } from "react-google-maps";

var windowWidth = window.innerWidth;
const foldername = process.env.REACT_APP_FOLDER_LAYOUT;
const GoogleMapsAPI = process.env.REACT_APP_API_KEY_GMAP;

class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoading2: false,
      mainClass: "page-wrapper",
      redirectProductDetails: false,
      selectedProductID: 0,
      selectedPath: "",
      needLogin: true,
      selectedCategory: "",
      eventid: "",
      noEvents: true,
      formData: {
        email: {
          label: getDefValues().emailLabel,
          value: "",
          type: "INPUT",
          validationtype: "email",
        },
        password: {
          label: getDefValues().passwordLabel,
          value: "",
          type: "PASSWORD",
          validationtype: "PASSWORD",
        },
      },
      latitude: -16.5337955,
      longitude: -68.0865515,
      address: "",
      city: "La Paz",
      showManageEvents: false,
      eventTypes: [],
    };
  }

  /*loading data*/
  componentDidMount = async () => {
    try {
      var eventId = getCookie(process.env.REACT_APP_ORG_NAME + "_eventId");
      if (eventId) {
        if (eventId > 0) {
          console.log("reset");
          this.props.clearEvent();
          window.location.reload();
        }
      } else {
        getShopCart("CREATEEVENTMODE");
      }
      this.props.hideCart();

      let _cart = this.props.shopCart;
      var products = [];
      if (_cart.products) {
        _cart.products.map(function (key) {
          var variant = "";
          key.optionsselected.map(function (v) {
            variant = variant + "," + v.optionvalue;
          });
          variant = variant.substr(1, variant.length);
          products.push({
            id: key.productid,
            sku: key.productsku,
            name: key.productname,
            price: parseFloat(toFixed(key.price)),
            brand: "CasaIdeas",
            category: key.category,
            variant: variant,
            quantity: key.quantity,
          });
        });
      }

      let storesInfo = getSyncStoresInfo();
      //console.log("storesInfoEVENTO", storesInfo)
      var storeName = "";
      if (storesInfo.stores) {
        let pos = find(
          storesInfo.stores,
          storesInfo.selectedStoreId,
          "storeId"
        );
        storeName = storesInfo.stores[pos].storeName;
      }

      var login = getLoginInfo();
      if (login.userId) {
        let resp4 = await callApi("admin", "getCustomerById", {
          customerid: login.userId,
        });
        this.setState({
          ...this.state,
          userDataInfo: resp4.USER,
          userAddInfo: resp4.ADDRESS,
        });

        var objData = {
          current_view: "pages/" + this.props.match.params.page,
          current_list: "pages/" + this.props.match.params.page,
          current_currency: "BOB",
          userId: getLoginInfo().userId ? getLoginInfo().userId : "0",
          cart_products: products,
          string_searched: "",
          store_name: process.env.REACT_APP_ORG_NAME + "-" + storeName,
          googleDynamicRemarketing: { ecomm_pagetype: "home" },
          event: "gtm.load",
          criteoParams: {
            PageType: "pages/" + this.props.match.params.page,
            email: "",
          },
          gdpr_marketing_status: "accepted",
          gdpr_statistics_status: "accepted",
        };

        setGTMEvent("loadPage", objData);

        var resp1 = await callApi("frontend", "getCustomerEventsByUserId", {
          userid: login.userId,
        });

        if (resp1.EVENTS.length > 0) {
          this.setState({ showManageEvents: true });
        }

        if (resp1.EVENTS.length > 1000) {
          //define max events per customer default 1000
          //console.log("ya tiene un evento en curso")
          this.setState({
            noEvents: false,
            title: getDefValues().alertTitleLabel,
            messages: [
              getDefValues().eventFoundsubTitleLabel1,
              getDefValues().eventFoundsubTitleLabel2,
            ],
          });
        } else {
          //var finalFoldername = foldername;
          var layoutFile = "createEventForm_" + getLanguage();
          let resp = await callApi(
            "layaoutManager",
            "getLayoutTemplateByName",
            { layoutFile: layoutFile, foldername: foldername + "private/" }
          );

          let _pageData = await loadPageV2(
            resp.template,
            this.managepagination,
            this.manageCart,
            this.changeHandler,
            this.manageButton,
            this.toggleModal,
            windowWidth
          );
          this.setState({ template: resp.template, userId: login.userId });
          await this.renderData(_pageData);
        }
      } else {
        this.setState({ modal1: true });
      }
      //this.setState({pageData:_pageData, template: resp.template});
    } catch (err) {
      console.log(">>>>>>>>>>", err);
    } finally {
      this.setState({ isLoading: false, needLogin: true });
    }
    //window.addEventListener("resize", this.handleResize);
  };

  renderData = async (_pageData) => {
    try {
      //	console.log(_pageData)
      var respE = await callApi("frontend", "getEventTypes", {
        languageid: "3",
      });
      this.setState({ eventTypes: respE.rows });
      if (this.props.match.params.eventType) {
        for (let i in respE.rows) {
          let field = respE.rows[i];
          if (
            field.STRING_VALUE.replace(" ", "")
              .replace(" ", "")
              .toUpperCase() ===
            this.props.match.params.eventType
              .replace("-", "")
              .replace("-", "")
              .toUpperCase()
          ) {
            let data = JSON.parse(field.CLOB_VALUE);
            _pageData[0].data.imagepath["value"] = data.imagepath;
          }
        }
      }

      for (let r in _pageData) {
        if (_pageData[r].data["directions"]) {
          _pageData[r].data["directions"].buttonlabel =
            getDefValues().setAddressLabel;
        }

        mergeFormData(
          _pageData[r].data,
          this.state.userDataInfo
        );

        this.setState({
          ["RENDEROBJ" + _pageData[r].ObjectName]: _pageData[r],
        });
      }
      if (_pageData[1].data.tipodoc.value === "") {
        _pageData[1].data.tipodoc.value = 1;
      }

      this.setState({ city: _pageData[2].data.city.values[0].text });
      //console.log("-final-", this.state)
    } catch (err) {
      console.log(">>>>>>>>>>>", err);
    }
  };

  toggleModal = (event) => {
    let object = findObjName(event);
    //console.log(object, this.state["RENDEROBJ"+object])
    let newTemplate = toggleModalManager(this.state["RENDEROBJ" + object]);
    this.setState({ ["RENDEROBJ" + object]: newTemplate });
  };

  changeHandler = async (event) => {
    //console.log(event.target)

    let object = findObjName(event);

    //console.log(event, object)

    let fieldName = object.split("__");
    if (fieldName[1] === "ADD") {
      this.setState({
        activeMapForm: fieldName[0],
        activeMapValue: fieldName[2],
      });
      this.showpopupdirections();
    } else {
      if (object.indexOf("city") >= 0) {
        //console.log("city")
        var city = "";
        if (event.target.value == "1") {
          city = "La Paz";
        }
        if (event.target.value == "2") {
          city = "Cochabamba";
        }
        if (event.target.value == "3") {
          city = "Santa Cruz";
        }

        if (event.target.value == "4") {
          city = "Tarija";
        }
        if (event.target.value == "5") {
          city = "Sucre";
        }
        if (event.target.value == "6") {
          city = "Potos\u00ED";
        }
        if (event.target.value == "7") {
          city = "Oruro";
        }
        if (event.target.value == "8") {
          city = "Cobija";
        }
        if (event.target.value == "9") {
          city = "Beni";
        }

        this.setState({ city: city });
      }

      let newTemplate = changeHandlerManager(
        this.state["RENDEROBJ" + fieldName[0]],
        fieldName[1],
        event.target.value
      );

      if (event?.target?.name === "datos_evento__eventtypeid") {
        let data = findValueById(
          this.state.eventTypes,
          event.target.value,
          "DETAILDOMAINID",
          "CLOB_VALUE"
        );

        try {
          let imageInfo = JSON.parse(data);
          newTemplate.data.imagepath["value"] = imageInfo.imagepath;
          newTemplate.finalData.formData.imagepath["value"] =
            imageInfo.imagepath;
          this.setState({ isLoading: true });
          setTimeout(
            function () {
              this.setState({ ["RENDEROBJ" + fieldName[0]]: newTemplate });
              this.setState({ isLoading: false });
            }.bind(this),
            250
          );
        } catch (e) {
          newTemplate.data.imagepath["value"] = "";
          newTemplate.finalData.formData.imagepath["value"] = "";
          this.setState({ isLoading: true });
          setTimeout(
            function () {
              this.setState({ ["RENDEROBJ" + fieldName[0]]: newTemplate });
              this.setState({ isLoading: false });
            }.bind(this),
            50
          );
        }
      } else {
        this.setState({ ["RENDEROBJ" + fieldName[0]]: newTemplate });
      }
    }
  };

  changeForm = async (event) => {
    this.setState({ isLoading: true });
    let object = findObjName(event);
    let fieldName = object.split("__");
    let newTemplate = this.state["RENDEROBJ" + fieldName[0]];
    newTemplate["activeForm"] = event.target.value;
    this.setState({ ["RENDEROBJ" + fieldName[0]]: newTemplate });
    setTimeout(
      function () {
        this.setState({ ["RENDEROBJ" + fieldName[0]]: newTemplate });
        this.setState({ isLoading: false });
      }.bind(this),
      250
    );
  };

  manageCart = async (event) => {
    //let object = findObjName(event);
    let container;
    if (event.target.name) {
      container = event.target.id;
    } else {
      container = event.target.parentNode.id;
    }

    try {
      this.setState({ isLoading2: true });
      var resp = await manageCart(
        event,
        this.state["RENDEROBJ" + container].data,
        this.manageCart,
        toast
      );
      if (resp.success) {
        if (!resp.redirect) {
          this.setState({
            ...this.state,
            isLoading: false,
            //cardData: resp._cardData
          });
          if (resp.dataCart) {
            this.props.updateCart(resp.dataCart);
          }
        } else {
          this.setState({
            ...this.state,
            isLoading: false,
            selectedProductID: resp.selectedProductID,
            selectedPath: resp.selectedPath,
            selectedCategory: resp.selectedCategory,
            redirectProductDetails: true,
          });
        }
      } else {
        this.setState({ ...this.state, isLoading: false });
        console.log(">>>>>>>", resp.message);
      }

      this.setState({ isLoading2: false });
    } catch (Excep) {
      this.setState({ isLoading2: false });
      console.log(">>>>>>>", Excep);
    }
  };

  showpopupdirections = async (event) => {
    this.setState({ isLoading2: true });
    let form = await callApi("layaoutManager", "getLayoutTemplateByName", {
      layoutFile: "addressForm_" + getLanguage(),
      foldername: foldername + "private/",
    });
    let rendegObj = await getRenderObj(
      form.template[0],
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    rendegObj.curdata.config["btncolor"] = "secondary";
    //delete rendegObj.curdata.data["alias"]
    rendegObj.curdata.data["city"].values = [
      { value: this.state.city, text: this.state.city },
    ];
    rendegObj.curdata.data["city"].value = this.state.city;
    this.setState({
      addressFormData: rendegObj.curdata.data,
      addressFormConfig: rendegObj.curdata.config,
      shippingFormId: form.template[0].componentid,
    });
    this.setState({
      modal4: true,
      showMap: false,
      showFormAdd: true,
      isLoading2: false,
    });
  };
  changeHandlerA = async (event) => {
    var formData = changeHandler(event, this.state.addressFormData);
    this.setState({ addressFormData: formData });
    if (event.key === "Enter") {
      this.saveTempLocation(event);
    }
  };
  closeMap = (event) => {
    this.setState({ ...this.state, modal4: false, showMap: false });
    this.props.toggleListener();
  };

  hideMap = (event) => {
    this.setState({ showMap: false, showFormAdd: true });
    this.props.toggleListener();
  };

  saveTempLocation = async (event) => {
    var resp = await validateForm(this.state.addressFormData, toast);
    if (resp.isValid) {
      this.props.toggleListener();
      this.setState({
        showMap: true,
        showFormAdd: false,
        address: this.state.addressFormData.address.value,
        city: this.state.addressFormData.city.value,
      });
    }
  };

  setMyLocation = async (event) => {
    this.setState({ showMap: false });
    await navigator.geolocation.getCurrentPosition(
      async function (position) {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
        this.setState({
          latitude: latitude,
          longitude: longitude,
          showMap: true,
        });
      }.bind(this)
    );
  };

  setLocation = async (event, add) => {
    console.log("setLocation");
    if (event.lat) {
      this.setState({
        clatitude: event.lat,
        clongitude: event.lng,
        caddress: add,
      });
    }
  };

  confirmLocation = async (event) => {
    //console.log("confirmLocation")
    this.setState({ isLoading2: true });
    try {
      let _formData = this.state.addressFormData;
      _formData = parseFormData(_formData);
      var finalValue = {};
      var finalLabel = "";
      Object.keys(_formData).map(function (field) {
        finalLabel = finalLabel + _formData[field] + ", ";
        finalValue[field] = _formData[field];
      });
      console.log(_formData);
      finalLabel = finalLabel + " LAT:" + event.lat + ", LNG:" + event.lng;

      let title = _formData["alias"].toUpperCase();
      var addLabel = _formData["address"];
      finalValue["latitude"] = event.lat;
      finalValue["longitude"] = event.lng;
      finalValue["zonecode"] = event.zonecode;
      var formData = this.state["RENDEROBJ" + this.state.activeMapForm]["data"];
      formData[this.state.activeMapValue].values = [
        {
          value: finalValue,
          text: title /*---*/,
          aditionalText: addLabel /*finalLabel*/,
        },
      ];

      //directions.values.push ( {value: 0 , text: directionsLabel,  aditionalText:addLabel} )

      formData[this.state.activeMapValue].value =
        formData[this.state.activeMapValue].values[0].value;

      this.setState({
        modal4: false,
        showMap: false,
        showFormAdd: true,
        haveDirection: true,
      });
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
    } finally {
      this.setState({ isLoading2: false });
    }
  };

  manageButton = async (event) => {
    try {
      this.setState({ isLoading2: true });
      let object = findObjName(event);
      var fieldName = object.split("__");
      let storesInfo = getSyncStoresInfo();
      var formData = {
        userId: this.state.userId,
        storeid: storesInfo.selectedStoreId,
      };
      var resp = await manageButton(fieldName[1], this.state, toast, formData);

      if (resp.success) {
        let _eventid = resp.message.split("ID:");
        let eventid = _eventid[1];
        this.setState({ isLoading2: false, modal2: true, eventid: eventid });

        localStorage.setItem(process.env.REACT_APP_ORG_NAME + "_haveEvent", 1);

        await this.props.setEventMode(
          eventid,
          this.state.RENDEROBJdatos_evento.data.eventname.value,
          "addEventList",
          0
        );
      } else {
        this.setState({ isLoading2: false });
      }
    } catch (err) {
      console.log(">>>>>>>>>>>", err);
      this.setState({ isLoading2: false });
    }
  };

  managepagination = (event) => {
    this.setState({ isLoading: true });

    //let object = findObjName(event);
    let _container;
    if (event.target.name) {
      _container = event.target.id.split("__");
    } else {
      _container = event.target.parentNode.id.split("__");
    }
    let container = _container[0];
    setTimeout(
      function () {
        var newpagesettings = managepagination(
          this.state["RENDEROBJ" + container].config.pagesettings,
          event
        );
        var newcardConfig = {
          pagewidth: "col-md-12 col-lg-12 col-xl-12",
          colsize: "col-6",
          colsizelg: "col-lg-2_5",
          colsizemd: "col-md-4",
          colsizesm: "col-sm-6", 
          pagination: true,
          managepagination: this.managepagination,
          pagesettings: newpagesettings,
          /*overtop            :"YES"*/
        };
        this.setState({ cardConfig: newcardConfig, isLoading: false });
      }.bind(this),
      1000
    );
  };
  managesort = (event) => {
    this.setState({ isLoading: true });
    //console.log(event.target)
    //let object = findObjName(event);
    let container;
    if (event.target.name) {
      container = event.target.id;
    } else {
      container = event.target.parentNode.id;
    }
    setTimeout(
      function () {
        var newcardData = managesort(
          this.state["RENDEROBJ" + container],
          event
        );
        this.setState({ cardData: newcardData, isLoading: false });
      }.bind(this),
      1000
    );
  };

  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });

    if (nr === 4 && !this.state.modalNumber) {
      this.setState({ showMap: false });
    }
  };

  callBackSN = async (res) => {
    try {
      this.setState({ isLoading: true });
      var status = await loginSN(res, toast, this.state.rememberme);
      if (status) {
        window.location.reload();
        //this.props.setLoginInfo(res)
        this.setState({ reloadPage: true });
      }
      this.setState({ isLoading: false });
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };

  callBackSNError = (res) => {
    console.log("Login failed: res:", res);
  };

  changeHandlerL = async (event) => {
    //let resp = await validateForm(this.state.formData, toast,event);
    //this.setState({formData:resp.formData});

    var formData = changeHandler(event, this.state.formData);
    this.setState({ formData: formData });

    if (event.key === "Enter") {
      this.login(event);
    }
  };

  login = async (event) => {
    try {
      this.setState({ isLoading: true });
      var resp = await login(this.state.formData, this.state.rememberme, toast);
      if (resp.success) {
        this.setState({ isLoading: false, modal1: false, reloadPage: true });
        this.props.setLoginInfo(event);
        window.location.reload();
      } else {
        this.setState({ isLoading: false });
      }
    } catch (Excep) {
      this.setState({ isLoading: false });
      console.log(">>>>>>>", Excep);
    }
  };

  toggleLogin = (nr) => () => {
    let show = "show" + nr;
    this.setState({
      [show]: !this.state[show],
    });
  };

  render() {
    if (!this.state.noEvents) {
      return (
        <NotFounded title={this.state.title} messages={this.state.messages} />
      );
    } else {
      const MapLoader = withScriptjs(Map);
      return this.props.showSearcher ? (
        ""
      ) : (
        <>
          <PageLoading isLoading={this.state.isLoading} />
          <PageLoading isLoading={this.state.isLoading2} />

          <MDBModal
            isOpen={this.state.modal4}
            toggle={this.toggle(4)}
            fade
            centered
            className="fullheigth-modal"
          >
            <MDBModalHeader>
              <span className="h6-responsive">
                {" "}
                {getDefValues().chooseMapPoint}{" "}
              </span>
            </MDBModalHeader>
            <MDBModalBody className="popup-map">
              {this.state.showFormAdd ? (
                <Form
                  formData={this.state.addressFormData}
                  changeHandler={this.changeHandlerA}
                  formConfig={this.state.addressFormConfig}
                />
              ) : (
                ""
              )}

              {this.state.showMap ? (
                <MapLoader
                  coords={{
                    lat: this.state.latitude,
                    lng: this.state.longitude,
                  }}
                  height="380px"
                  setMyLocation={this.setMyLocation}
                  setLocation={this.setLocation}
                  address={this.state.address}
                  city={this.state.city}
                  googleMapURL={
                    "https://maps.googleapis.com/maps/api/js?key=" +
                    GoogleMapsAPI +
                    "&libraries=places"
                  }
                  backLocation={this.hideMap}
                  cancelLocation={this.closeMap}
                  btncolor="secondary"
                  confirmLocation={this.confirmLocation}
                  loadingElement={<div style={{ height: `380px` }}></div>}
                />
              ) : (
                ""
              )}
            </MDBModalBody>

            <div className="card">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 flex items-center">
                  {this.state.showFormAdd ? (
                    <>
                      <button
                        className={
                          "btn btn-md btn-secondary shipping-form-btn mr-1"
                        }
                        type="button"
                        onClick={this.saveTempLocation}
                      >
                        {getDefValues().selectLocationLabel}
                      </button>
                      <button
                        className="btn btn-md btn-outline-secondary shipping-form-btn"
                        type="button"
                        onClick={this.closeMap}
                      >
                        {getDefValues().cancelLabel}
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </MDBModal>

          <MDBModal
            isOpen={this.state.modal1}
            toggle={this.toggle(1)}
            fade
            centered
            className="fullheigth-modal"
          >
            <MDBModalBody>
              <p className="flex items-left mt-3">
                <img src={ico_login} alt="login" />
                <span className="h3-responsive">
                  {" "}
                  {getDefValues().loginLabel}{" "}
                </span>
              </p>

              {this.state.needLogin ? (
                <MDBAlert color="warning" className="">
                  <p sytle={{ marginBottom: 5 }}>
                    <span className="h5-responsive">
                      {" "}
                      {getDefValues().needLoginMessage}{" "}
                    </span>
                  </p>
                </MDBAlert>
              ) : (
                ""
              )}

              <div className="mb-20 mt-10"></div>

              <Login
                formData={this.state.formData}
                toggleF={this.toggleLogin("forgot")}
                toggleL={this.toggleLogin("login")}
                toggleR={this.toggleLogin("recovery")}
                changeHandler={this.changeHandlerL}
                needLogin={this.state.needLogin}
                login={this.login}
                guest={this.guest}
              />

              <div className="text-muted flex items-center mt-3">
                <span>{getDefValues().loginWithSocialNetwork}</span>
              </div>

              <div className="login-or mt-30">
                <span className="or-line"></span>
                <span className="span-or">{getDefValues().orLabel}</span>
              </div>

              <p className="mobile-col col-12 social-login text-center">
                <GoogleLogin
                  clientId={process.env.REACT_APP_API_KEY_GOOGLE}
                  buttonText="Google"
                  className="google"
                  onSuccess={this.callBackSN}
                  onFailure={this.callBackSNError}
                  cookiePolicy={"single_host_origin"}
                />
                {/*
							<FacebookLogin
								cssClass="ml-2 facebook"
								appId={process.env.REACT_APP_API_KEY_LOGIN_FB}
								autoLoad={false}
								fields="name,email,picture,last_name,first_name"
								scope="public_profile, email"
								callback={this.callBackSN}
								onFailure={this.callBackSNError}
								textButton="Facebook"
								icon="fa-facebook" />
							 */}
              </p>
            </MDBModalBody>
          </MDBModal>

          <div className="page-wrapper" onClick={this.props.hideCart}>
            <EventMessage
              hideClose={true}
              modal={this.state.modal2}
              eventid={this.state.eventid}
              eventSuccess={true}
              setEventMode={this.props.setEventMode}
            />

            {this.state.redirectProductDetails ? (
              <Redirect
                to={{
                  pathname:
                    (this.state.selectedCategory
                      ? "/" + this.state.selectedCategory + "/"
                      : "/productDetails/") +
                    this.state.selectedProductID +
                    "/" +
                    (this.state.selectedPath
                      ? this.state.selectedPath
                      : "show"),
                }}
              />
            ) : (
              ""
            )}

            <div className="content-2 container-page container-fluid custom-container">
              {this.state.showMap ? (
                ""
              ) : (
                <>
                  <div className="row flex items-center">
                    <div className="col-md-12 col-lg-9 col-lx-7">
                      {this.state.showManageEvents && (
                        <div className="row my-1">
                          <div className="col-12 text-right">
                            <a
                              href="/customer/event/manage"
                              className="btn btn-sm btn-secondary"
                            >
                              {getDefValues().goManageEventLabel.toUpperCase()}
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row flex items-center">
                    <div className="col-md-12 col-lg-9 col-lx-7">
                      <div className="card ">
                        {Object.keys(this.state).map((obj) => (
                          <>
                            {obj.startsWith("RENDEROBJ") &&
                            !this.state.isLoading ? (
                              <>
                                {this.state[obj].type === "TEXT" ? (
                                  <Text
                                    name={this.state[obj].ObjectName}
                                    textData={this.state[obj].data}
                                    textConfig={this.state[obj].config}
                                  />
                                ) : (
                                  ""
                                )}
                                {this.state[obj].type === "IMAGE" ? (
                                  <Image
                                    name={this.state[obj].ObjectName}
                                    imageData={this.state[obj].data}
                                    imageConfig={this.state[obj].config}
                                  />
                                ) : (
                                  ""
                                )}
                                {this.state[obj].type === "VIDEO" ? (
                                  <Video
                                    name={this.state[obj].ObjectName}
                                    videoData={this.state[obj].data}
                                    videoConfig={this.state[obj].config}
                                  />
                                ) : (
                                  ""
                                )}
                                {this.state[obj].type === "LIST" ? (
                                  <List
                                    name={this.state[obj].ObjectName}
                                    listValues={this.state[obj].data}
                                  />
                                ) : (
                                  ""
                                )}
                                {this.state[obj].type === "BLOGCONTAINER" ? (
                                  <BlogContainer
                                    name={this.state[obj].ObjectName}
                                    blogContainerData={this.state[obj].data}
                                    blogContainerContent={
                                      this.state[obj].content
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                                {this.state[obj].type === "CATEGORYBLOG" ? (
                                  <CategoryBlog
                                    name={this.state[obj].ObjectName}
                                    categoryBlogData={this.state[obj].data}
                                    categoryBlogContent={
                                      this.state[obj].content
                                    }
                                    categoryBlogConfig={this.state[obj].config}
                                  />
                                ) : (
                                  ""
                                )}
                                {this.state[obj].type === "FORM" ? (
                                  <Form
                                    name={this.state[obj].ObjectName}
                                    container={this.state[obj].ObjectName}
                                    formData={this.state[obj].data}
                                    activeForm={this.state[obj].activeForm}
                                    changeForm={this.changeForm}
                                    changeHandler={this.changeHandler}
                                    manageButton={this.manageButton}
                                    formConfig={this.state[obj].config}
                                  />
                                ) : (
                                  ""
                                )}
                                {this.state[obj].type === "SLIDERPRODUCTS" ? (
                                  <div className="sliderproducts">
                                    <div className="custom-container">
                                      <Slider
                                        name={this.state[obj].ObjectName}
                                        sliderData={this.state[obj].data}
                                        sliderConfig={this.state[obj].config}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {this.state[obj].type === "CARDPRODUCTS" ? (
                                  <Card
                                    name={this.state[obj].ObjectName}
                                    cardData={this.state[obj].data}
                                    cardConfig={this.state[obj].config}
                                  />
                                ) : (
                                  ""
                                )}

                                {this.state[obj].type === "CARACOL" ? (
                                  <Caracol
                                    name={this.state[obj].ObjectName}
                                    caracolData={this.state[obj].data}
                                    caracolConfig={this.state[obj].config}
                                  />
                                ) : (
                                  ""
                                )}
                                
                                {this.state[obj].type === "CARDCONTAINER" ? (
                                  <Card
                                    name={this.state[obj].ObjectName}
                                    cardData={this.state[obj].data}
                                    cardConfig={this.state[obj].config}
                                  />
                                ) : (
                                  ""
                                )}
                                {this.state[obj].type === "SLIDERIMAGE" ? (
                                  <SliderImage
                                    sliderData={this.state[obj].data}
                                    sliderConfig={this.state[obj].config}
                                  />
                                ) : (
                                  ""
                                )}
                                {this.state[obj].type === "MODAL" ? (
                                  <Modal
                                    name={this.state[obj].ObjectName}
                                    modalData={this.state[obj].data}
                                    modalConfig={this.state[obj].config}
                                    toggle={this.toggleModal}
                                  />
                                ) : (
                                  ""
                                )}
                                {this.state[obj].type === "SLIDER" ||
                                this.state[obj].type === "SLIDERCONTAINER" ? (
                                  <Slider
                                    name={this.state[obj].ObjectName}
                                    sliderData={this.state[obj].data}
                                    sliderConfig={this.state[obj].config}
                                  />
                                ) : (
                                  ""
                                )}
                                {this.state[obj].type === "BUTTON" ? (
                                  <Button
                                    name={this.state[obj].ObjectName}
                                    buttonData={this.state[obj].data}
                                    buttonConfig={this.state[obj].config}
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      );
    }
  }
}
export default page;
